export type KorokType = {
  location: string;
  id: string;
  multiple: number;
  fullEnName?: string;
  name: string;
  koName?: string;
  position: {
    x: number;
    y: number;
  };
};

export const SkyKorok: KorokType[] = [
  {
    location: 'sky',
    multiple: 1,
    name: '1414',
    id: '9da303a0-07ca-4908-8d7f-ffe1fc170c6b',
    position: {
      x: 1375.273556231003,
      y: 1441.3243243243242,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1415',
    id: '6137e74a-7021-44ad-a754-6dfc86ed29a6',
    position: {
      x: 1451.4133738601824,
      y: 1400.7992277992278,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1416',
    id: 'eaddbad0-38ec-4208-828e-f683f252dd2b',
    position: {
      x: 1420.9574468085107,
      y: 1446.3899613899614,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1417',
    id: '86b9814f-1d7d-46cb-be82-c4cb782557ae',
    position: {
      x: 1324.5136778115502,
      y: 1471.7181467181467,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1418',
    id: '1970cf87-ba86-43e5-99d1-1eb5a7766b4a',
    position: {
      x: 1395.5775075987842,
      y: 1395.7335907335905,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1419',
    id: '7bf22b59-81ed-4c41-b0a1-dffe28c44b3e',
    position: {
      x: 1360.045592705167,
      y: 1491.9806949806948,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1420',
    id: '5c3a2b80-e862-406d-9453-e740e8ab97d9',
    position: {
      x: 1329.5896656534956,
      y: 1279.2239382239381,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1421',
    id: 'b0596135-b559-43f6-89d1-8cbd1c0ccd69',
    position: {
      x: 1344.8176291793313,
      y: 1421.061776061776,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1422',
    id: '23f5c2b0-e6fe-454a-812f-eb652946ea90',
    position: {
      x: 1400.6534954407296,
      y: 1456.5212355212354,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1423',
    id: '250c1e46-42f2-4439-9776-fc0b643b5254',
    position: {
      x: 1420.9574468085107,
      y: 1476.7837837837837,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1425',
    id: 'ff2742d2-93c9-4a70-bca9-c931e38ec7db',
    position: {
      x: 1278.8297872340427,
      y: 1451.4555984555984,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1427',
    id: 'df6f510d-7ecf-476e-9cb9-54bd20d04948',
    position: {
      x: 1354.969604863222,
      y: 1497.046332046332,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1428',
    id: '2d3ce6e5-6844-4fed-9823-40c0ca86e3e7',
    position: {
      x: 1380.3495440729484,
      y: 1294.4208494208492,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1429',
    id: 'ca590b6d-f16f-4d20-b6df-7be428510dbe',
    position: {
      x: 1299.1337386018238,
      y: 1340.0115830115828,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1430',
    id: '96472046-53ac-4317-941b-811136699946',
    position: {
      x: 1380.3495440729484,
      y: 1446.3899613899614,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1803',
    id: '50778d5f-4fb2-4cd0-ac8b-15cca581d6a8',
    position: {
      x: 1395.5775075987842,
      y: 443.3938223938224,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1804',
    id: 'a0d0d239-e17d-4af7-a8fd-f1680946330a',
    position: {
      x: 1339.7416413373862,
      y: 625.7567567567568,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1805',
    id: '907b5e65-1d95-4380-ab04-ad0b72efc084',
    position: {
      x: 1669.6808510638298,
      y: 808.1196911196911,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1806',
    id: 'c8eba915-d77c-49e7-8ea5-e9b1a1b4dd0d',
    position: {
      x: 1755.9726443768998,
      y: 808.1196911196911,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1807',
    id: '412e34fd-8774-4738-b3ea-4e0aafa71d55',
    position: {
      x: 1111.322188449848,
      y: 261.03088803088804,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1808',
    id: 'f2d893bb-7ab9-4aba-ba4f-0d032665413f',
    position: {
      x: 877.8267477203648,
      y: 250.8996138996139,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1809',
    id: '68966603-6048-4842-85a9-139ab3d5aeff',
    position: {
      x: 882.9027355623101,
      y: 316.75289575289577,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1810',
    id: 'afee3f37-7a58-4aa2-b2f1-152dfd2ae995',
    position: {
      x: 517.4316109422492,
      y: 296.4903474903475,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1811',
    id: 'b95cf1b2-7b7d-4318-958f-2564f5a6d7b1',
    position: {
      x: 573.2674772036474,
      y: 352.2123552123552,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1812',
    id: 'c466265b-c200-4f92-8e26-7864f385b9ea',
    position: {
      x: 355,
      y: 413,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1813',
    id: '133eec68-410a-4ed7-b825-819e29bbc403',
    position: {
      x: 720.4711246200609,
      y: 884.1042471042471,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1814',
    id: 'a6aa6cbd-93ce-46d6-bb90-89f0b3bc5b68',
    position: {
      x: 547.887537993921,
      y: 585.2316602316603,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1815',
    id: 'c6ff72f4-8ffa-42fd-a11c-a0826fe320fd',
    position: {
      x: 344.84802431610944,
      y: 924.6293436293436,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1816',
    id: 'ebfb6ec0-9797-4999-87c4-4c6dd868b2ab',
    position: {
      x: 420.9878419452888,
      y: 843.5791505791506,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1817',
    id: '53fc41ba-cb4f-48bd-9bdc-662c7a7962af',
    position: {
      x: 431.13981762917933,
      y: 858.7760617760617,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1818',
    id: '1f1d283a-4416-4d86-9587-5904267a2c13',
    position: {
      x: 420.9878419452888,
      y: 853.7104247104246,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1819',
    id: '28950a49-3a1f-44e5-b66f-3488aa477bed',
    position: {
      x: 2131.595744680851,
      y: 757.4633204633204,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1820',
    id: 'ee8418ff-e812-412b-a45b-d793b19e64e5',
    position: {
      x: 2400.6231003039516,
      y: 210.37451737451738,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1821',
    id: '5bf4de16-3f32-498f-9ea9-ed6735363b85',
    position: {
      x: 2365.091185410334,
      y: 585.2316602316603,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1842',
    id: 'b818efa3-53c7-4c2d-a10a-3a7e14aa4b79',
    position: {
      x: 2405.699088145897,
      y: 1279.2239382239381,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1843',
    id: '03741fba-7e94-4da6-b37f-2909eec89b9d',
    position: {
      x: 2385.3951367781156,
      y: 1360.2741312741314,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1844',
    id: '9be38ea4-8e22-4c00-be2b-c5e2fd298119',
    position: {
      x: 2349.8632218844987,
      y: 1299.4864864864865,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1845',
    id: '3d478258-b3b0-49bb-bc5a-3c00a9175551',
    position: {
      x: 2075.759878419453,
      y: 944.8918918918919,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1846',
    id: '0767099f-cb80-4a77-92f8-fa8f8ffb8612',
    position: {
      x: 481.8996960486322,
      y: 1096.8610038610038,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1847',
    id: '4eae4788-2558-43b2-88f5-3e90d6a0c9f4',
    position: {
      x: 872.7507598784194,
      y: 1927.6254826254826,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1848',
    id: 'f0e3a8ec-df65-45a6-8cd9-f84db11be48e',
    position: {
      x: 1689.9848024316111,
      y: 1309.6177606177607,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1849',
    id: '1eefa412-8338-4474-908c-8cee59159240',
    position: {
      x: 1669.6808510638298,
      y: 1532.5057915057914,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1850',
    id: '180c312a-6a02-4bfe-a111-a8bc78fe793b',
    position: {
      x: 1588.4650455927053,
      y: 1573.030888030888,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1851',
    id: '240d52c5-81ec-4c16-ac4f-1b36b89c9fe3',
    position: {
      x: 1964.0881458966567,
      y: 1785.7876447876447,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1852',
    id: '85192683-bd1a-499c-8511-6dce09e01a84',
    position: {
      x: 1847.340425531915,
      y: 1507.177606177606,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1853',
    id: '566c9a3b-88b8-49bd-b30f-8232f5bfeaae',
    position: {
      x: 1847.340425531915,
      y: 1491.9806949806948,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1854',
    id: 'ba7b8f60-0b87-4c6b-be08-ba6f88c0fae5',
    position: {
      x: 1821.9604863221884,
      y: 1497.046332046332,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1855',
    id: '7a7ae0d9-178b-4c62-8811-4c67721dd450',
    position: {
      x: 1821.9604863221884,
      y: 1517.3088803088801,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1856',
    id: '1f144675-f857-4e9b-aaaf-69d197706992',
    position: {
      x: 1304.209726443769,
      y: 1674.3436293436293,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1857',
    id: '83b6b3bd-d57a-4580-83e5-31999f3dd9ad',
    position: {
      x: 2035.1519756838907,
      y: 980.3513513513514,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1858',
    id: '3c589e83-2a3b-4af5-9a89-53fcd0423820',
    position: {
      x: 2096.063829787234,
      y: 949.9575289575289,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1859',
    id: 'f958f5ba-31fc-4eec-86cb-4e12ee6a7bf4',
    position: {
      x: 943.8145896656536,
      y: 1446.3899613899614,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1860',
    id: '4567a963-a695-431f-9e87-712a6452ba9c',
    position: {
      x: 1085.9422492401218,
      y: 1461.5868725868725,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1861',
    id: '0796d78b-ce73-48aa-8213-5961280d2bcc',
    position: {
      x: 1913.3282674772038,
      y: 1730.065637065637,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1862',
    id: '0f886d4a-5192-4473-9f5f-33c8645219cb',
    position: {
      x: 821.9908814589667,
      y: 1517.3088803088801,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1863',
    id: 'f641b807-bac4-4aeb-8fed-8cf042d4844e',
    position: {
      x: 1588.4650455927053,
      y: 1887.100386100386,
    },
  },
  {
    location: 'sky',
    multiple: 1,
    name: '1864',
    id: '91fe2c0a-4017-4df9-9806-74b0721786eb',
    position: {
      x: 1502.1732522796353,
      y: 1836.4440154440153,
    },
  },
];

export const SurfaceKorok: KorokType[] = [
  {
    location: 'surface',
    multiple: 1,
    name: '1002',
    id: 'd8352831-62d4-4d04-b4b4-3b73c85dc47e',
    position: {
      x: 1739.861111111111,
      y: 1430.9237536656892,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1003',
    id: '7a6a105b-2a5b-4ce3-85f8-15b5432412a8',
    position: {
      x: 2335.152777777778,
      y: 1278.5483870967741,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1005',
    id: '0e1c25bc-00b4-4f37-bf78-b004e56ab946',
    position: {
      x: 712.0925925925926,
      y: 811.2639296187683,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1006',
    id: 'e3f46100-8861-4bbf-bf58-f207598f3373',
    position: {
      x: 656.125,
      y: 872.2140762463343,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1007',
    id: 'd0823a44-9cc7-451c-b7bd-2d59b6c30044',
    position: {
      x: 350.84722222222223,
      y: 912.8475073313783,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1008',
    id: '5a9004d9-1f25-4676-871a-ac146f48a311',
    position: {
      x: 350.84722222222223,
      y: 862.0557184750733,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1010',
    id: 'b83ea3ae-7a8c-49ee-9b75-b74102f284fc',
    position: {
      x: 849.4675925925926,
      y: 1100.7771260997067,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1011',
    id: '5aa04562-6103-4596-abe4-fc4e1a617721',
    position: {
      x: 666.3009259259259,
      y: 1156.648093841642,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1012',
    id: 'd3029497-af6a-4fb7-bec9-dec769cc121d',
    position: {
      x: 432.2546296296296,
      y: 658.8885630498534,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1013',
    id: '5091c1c1-9392-4ad5-b120-259f8d4d04c8',
    position: {
      x: 1444.7592592592591,
      y: 755.3929618768328,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1015',
    id: 'bee470d0-d841-45ca-a704-5fe7e7bcc384',
    position: {
      x: 1470.1990740740741,
      y: 750.3137829912023,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1016',
    id: 'fc54920d-2bfe-4b28-8099-3ac08d0f47c8',
    position: {
      x: 422.0787037037037,
      y: 1268.390029325513,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1017',
    id: 'f7ddeee4-87a6-45d7-a846-dbf5a1a8d8c4',
    position: {
      x: 523.8379629629629,
      y: 1324.2609970674487,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1018',
    id: '607c4011-2eb7-4bf6-a0d7-19bb1d7e03e0',
    position: {
      x: 564.5416666666666,
      y: 1385.2111436950147,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1019',
    id: '662528ef-ffa1-46ee-a35b-5d71ca942b26',
    position: {
      x: 554.3657407407406,
      y: 1324.2609970674487,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1020',
    id: '8d034898-98c5-4a32-b0d9-3d91683168b8',
    position: {
      x: 1063.162037037037,
      y: 999.1935483870967,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1021',
    id: '7d6a8a11-4f87-4946-bea2-d2c858e4e48e',
    position: {
      x: 386.46296296296293,
      y: 1039.826979472141,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1022',
    id: '3324b3b4-e24e-48c1-b8f2-4eb9a26feeb2',
    position: {
      x: 1510.9027777777778,
      y: 719.8387096774193,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1023',
    id: 'dfde1b30-d410-4c67-8c63-f14b5b9da397',
    position: {
      x: 1256.5046296296296,
      y: 801.1055718475073,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1024',
    id: '4e4c4c8a-a597-4058-b82e-70aaa4ccbec7',
    position: {
      x: 1220.888888888889,
      y: 907.7683284457478,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1025',
    id: '4385cd43-3f3d-40fb-846d-9a460baf8f9c',
    position: {
      x: 1220.888888888889,
      y: 816.3431085043989,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1026',
    id: 'cc7c0b8b-3b34-4cb0-9716-f3a3a0dd14e8',
    position: {
      x: 1220.888888888889,
      y: 938.2434017595308,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1027',
    id: '98ea9b5d-70d9-4bae-8069-dcd5f2f48a9f',
    position: {
      x: 1159.8333333333333,
      y: 917.9266862170088,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1028',
    id: 'f9a7444d-5338-43c6-8924-211f1e0b83b9',
    position: {
      x: 1144.5694444444443,
      y: 923.0058651026393,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1030',
    id: '3e9479f7-31df-4096-b9c6-9e376b540b35',
    position: {
      x: 1063.162037037037,
      y: 887.4516129032259,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1031',
    id: 'aae1c35c-9c68-414a-9d3f-ece2e34d5b3d',
    position: {
      x: 1047.8981481481483,
      y: 948.4017595307918,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1032',
    id: 'fb86a8c1-7b00-4de8-91a4-98dbb7ca25b6',
    position: {
      x: 1521.0787037037037,
      y: 663.9677419354839,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1033',
    id: 'd95496ee-44ad-4456-9e3d-73afe094ebdf',
    position: {
      x: 1317.5601851851852,
      y: 938.2434017595308,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1034',
    id: '6774b67f-1fc2-47a9-a51e-9c4c56b8e0a2',
    position: {
      x: 1098.7777777777778,
      y: 1085.5395894428152,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1035',
    id: 'ac9e4bf7-3d8e-4881-9a95-a3b39ef76491',
    position: {
      x: 1185.2731481481483,
      y: 1116.0146627565982,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1036',
    id: 'f2d8f2d4-c984-4cae-a602-4e067bfb3798',
    position: {
      x: 1017.3703703703703,
      y: 1171.8856304985338,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1037',
    id: 'f3da61ad-8655-42aa-aad8-f6a49895984f',
    position: {
      x: 1032.6342592592591,
      y: 1202.3607038123168,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1038',
    id: '2baeb7b0-68c0-4ece-bbe3-f10dfe7904db',
    position: {
      x: 1027.5462962962963,
      y: 1217.5982404692081,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1040',
    id: '34714fca-0219-4d41-83ef-3e894fea7710',
    position: {
      x: 1149.6574074074074,
      y: 1212.5190615835777,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1041',
    id: '8e82d657-9a22-4144-a811-464e1f5d1878',
    position: {
      x: 1302.2962962962963,
      y: 1156.648093841642,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1042',
    id: 'e10a7282-4a9c-4b4f-874e-ad39d19a2093',
    position: {
      x: 1434.5833333333333,
      y: 1258.2316715542522,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1043',
    id: '016a8850-e24e-48e5-9eec-1f38a11e1fec',
    position: {
      x: 1434.5833333333333,
      y: 1100.7771260997067,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1044',
    id: '3b684634-46b2-434f-9259-29271e829b8b',
    position: {
      x: 1485.4629629629628,
      y: 1090.6187683284456,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1046',
    id: '7e1840de-62f7-4688-b10b-f03fcc3dbce0',
    position: {
      x: 1378.6157407407406,
      y: 973.7976539589442,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1047',
    id: 'ac6836cb-1b96-41da-8293-22103ff22181',
    position: {
      x: 1332.8240740740741,
      y: 872.2140762463343,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1049',
    id: 'e9a19803-a249-4956-bffc-25fe1147ecff',
    position: {
      x: 1348.0879629629628,
      y: 917.9266862170088,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1050',
    id: 'afbb2d24-6f2d-4791-ae96-32f7dbd217a7',
    position: {
      x: 1129.3055555555557,
      y: 953.4809384164223,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1051',
    id: 'fc401703-ab28-4593-b88e-bb39bc3c34b7',
    position: {
      x: 1098.7777777777778,
      y: 948.4017595307918,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1052',
    id: '60c0c416-0766-474a-ad58-e32dbfd395b2',
    position: {
      x: 1144.5694444444443,
      y: 1334.4193548387098,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1053',
    id: '034e697e-297e-4ae9-99bd-38402b9c3582',
    position: {
      x: 1602.486111111111,
      y: 770.6304985337243,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1054',
    id: '167d6daa-6e28-49ae-91fa-49d007c07b8c',
    position: {
      x: 620.5092592592592,
      y: 917.9266862170088,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1055',
    id: 'ff6a7019-d45e-4bcc-b866-bb46ba4d629f',
    position: {
      x: 600.1574074074074,
      y: 953.4809384164223,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1057',
    id: '780b2d2e-616b-4a57-9a60-36c5d6b09694',
    position: {
      x: 1032.6342592592591,
      y: 780.7888563049853,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1058',
    id: '95a2ba98-720f-4e02-b831-52e9d1c11886',
    position: {
      x: 1012.2824074074074,
      y: 928.0850439882698,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1059',
    id: 'b6e59ccf-35e5-4c8e-92a7-170ac9d3d996',
    position: {
      x: 1597.398148148148,
      y: 912.8475073313783,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1060',
    id: 'c9873b60-6732-4368-914e-fe8ab4adabca',
    position: {
      x: 1129.3055555555557,
      y: 877.2932551319648,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1061',
    id: '1dd2dd29-5275-4a49-afec-a7f8566dda7a',
    position: {
      x: 1129.3055555555557,
      y: 856.9765395894428,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1062',
    id: '763c5948-5b23-4829-bd63-9cb904736e6d',
    position: {
      x: 1633.013888888889,
      y: 923.0058651026393,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1063',
    id: 'dfefab1d-e45d-4299-9296-3670361a36ac',
    position: {
      x: 1658.4537037037037,
      y: 912.8475073313783,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1064',
    id: '557d8c21-4271-4edd-88c3-b29652e45031',
    position: {
      x: 1724.5972222222222,
      y: 897.6099706744868,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1065',
    id: 'ac6806f0-8718-4089-928f-006cfd3bac76',
    position: {
      x: 971.5787037037037,
      y: 958.5601173020527,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1066',
    id: 'beffe406-62dd-4cf8-a9a0-2530b6207e3e',
    position: {
      x: 1114.0416666666665,
      y: 1034.7478005865103,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1067',
    id: '4eee0d7e-a483-49bd-9d4f-ef915f2aa5a6',
    position: {
      x: 1744.9490740740741,
      y: 801.1055718475073,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1068',
    id: '64b3a0e1-9f8b-418e-9a57-72e0c80a92fb',
    position: {
      x: 1068.25,
      y: 1090.6187683284456,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1069',
    id: '7656d511-0254-4d9a-8cc2-a33520f80719',
    position: {
      x: 1022.4583333333333,
      y: 1075.3812316715544,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1070',
    id: '46cfe6e7-d37c-48d8-9050-68b504a39dba',
    position: {
      x: 1017.3703703703703,
      y: 1029.6686217008796,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1071',
    id: '94ce6b48-ed2f-42f0-a8c5-6092e2fe6b53',
    position: {
      x: 1027.5462962962963,
      y: 1126.173020527859,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1072',
    id: '39a6f6b1-5c07-47c4-951f-7a67746274d3',
    position: {
      x: 966.4907407407408,
      y: 1126.173020527859,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1073',
    id: '504406d7-61ac-48c6-a4bf-d9504c72285e',
    position: {
      x: 1103.8657407407409,
      y: 1237.9149560117303,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1074',
    id: '18383d11-c1c5-4348-abce-d6a500daadb7',
    position: {
      x: 1017.3703703703703,
      y: 1319.1818181818182,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1075',
    id: '49b8403b-9d71-4205-83c8-e679a0b52544',
    position: {
      x: 971.5787037037037,
      y: 1242.9941348973607,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1076',
    id: '8a944bab-3747-409c-b43b-15dce25af78c',
    position: {
      x: 956.3148148148148,
      y: 1192.2023460410558,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1077',
    id: 'adf16aaf-fe22-42ad-9530-6a4b77e19f86',
    position: {
      x: 1826.3564814814815,
      y: 862.0557184750733,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1078',
    id: '3bebb3af-9bfc-465f-bc32-446c76e9984a',
    position: {
      x: 1861.9722222222222,
      y: 790.9472140762464,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1079',
    id: '63e6275e-8aea-495c-b793-db3f35d408d1',
    position: {
      x: 1917.9398148148148,
      y: 826.5014662756598,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1080',
    id: '501ed4fc-3984-489e-98a5-b06d5006bf6b',
    position: {
      x: 1928.1157407407406,
      y: 806.1847507331378,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1081',
    id: '8c6c8a30-c1aa-415a-9a67-361e3d1c9896',
    position: {
      x: 1933.2037037037037,
      y: 760.4721407624634,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1082',
    id: 'd2c01d7c-19e0-4773-95c1-06de3dd0b1a3',
    position: {
      x: 1923.0277777777778,
      y: 669.0469208211143,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1083',
    id: 'c2fcfbde-7479-4004-822d-6a7492862ded',
    position: {
      x: 1856.8842592592591,
      y: 679.2052785923754,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1084',
    id: 'e7d9d083-de1e-4f20-99ec-4da2c72b53ba',
    position: {
      x: 1821.2685185185185,
      y: 653.8093841642228,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1085',
    id: 'a2ea2332-1730-45f9-b5fc-966ed9fb792f',
    position: {
      x: 1622.8379629629628,
      y: 719.8387096774193,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1086',
    id: 'e394a8d0-43d4-473c-a82a-351f3f99fbf0',
    position: {
      x: 1627.9259259259259,
      y: 674.1260997067449,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1087',
    id: 'c12732e8-d6f4-4fd6-92f0-d510bea4bc33',
    position: {
      x: 1225.9768518518517,
      y: 983.9560117302053,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1088',
    id: 'dead0442-d4e4-4756-ad39-3b20673d47c0',
    position: {
      x: 1210.712962962963,
      y: 1004.2727272727273,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1089',
    id: '60c01633-4b75-4b6a-a84b-f01057c7fbaf',
    position: {
      x: 1266.6805555555557,
      y: 989.0351906158357,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1090',
    id: '843205c0-a26d-4aea-bb7f-d3cb68162964',
    position: {
      x: 1225.9768518518517,
      y: 1019.5102639296188,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1093',
    id: '70139006-496c-4ad6-a45e-bedbbb64bdf4',
    position: {
      x: 1622.8379629629628,
      y: 623.3343108504398,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1094',
    id: '0e62c8c3-7fd5-4d75-bc4c-d51c782c1ba1',
    position: {
      x: 1683.8935185185185,
      y: 669.0469208211143,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1095',
    id: '4c59db93-2b68-4814-ac9e-a750edf8011f',
    position: {
      x: 1414.2314814814815,
      y: 963.6392961876833,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1096',
    id: '3d2f2828-412c-41aa-a914-9ccca5fc4f51',
    position: {
      x: 1454.9351851851852,
      y: 994.1143695014663,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1097',
    id: 'a7bcfc3a-abfa-4471-99d8-8e96c2fcee4b',
    position: {
      x: 1465.111111111111,
      y: 1080.4604105571848,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1098',
    id: '026611d5-d11d-49ea-9543-fe6141a34509',
    position: {
      x: 1465.111111111111,
      y: 1187.123167155425,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1099',
    id: '09744df9-99df-4cbd-9112-0bfb2be0daa5',
    position: {
      x: 1404.0555555555554,
      y: 1192.2023460410558,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1100',
    id: 'f13f55db-5d92-41cb-bd24-5abb2d642f6f',
    position: {
      x: 1353.1759259259259,
      y: 1136.3313782991204,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1101',
    id: 'd62602ac-e92b-439d-a76b-f2c36efe66f0',
    position: {
      x: 1337.912037037037,
      y: 1288.7067448680352,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1102',
    id: '100017a2-2a4e-4e1b-aaa3-63fb2b215032',
    position: {
      x: 1256.5046296296296,
      y: 1298.865102639296,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1104',
    id: '3a9b2102-0008-4a21-b8ac-826a420bd542',
    position: {
      x: 1236.1527777777778,
      y: 1171.8856304985338,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1105',
    id: '90926839-b835-44ba-b7cf-f03d44e7986c',
    position: {
      x: 1215.8009259259259,
      y: 1212.5190615835777,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1106',
    id: '555c8807-7e11-4864-93db-2249056b6f7a',
    position: {
      x: 1231.0648148148148,
      y: 933.1642228739003,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1107',
    id: '4eb9e78f-5403-4420-8f61-eea4594792ee',
    position: {
      x: 1205.625,
      y: 897.6099706744868,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1108',
    id: 'c90504ec-32b6-4542-bd3b-68067f25493e',
    position: {
      x: 1185.2731481481483,
      y: 882.3724340175953,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1109',
    id: '6732821e-8101-46d4-b42b-30fb773b76aa',
    position: {
      x: 1205.625,
      y: 887.4516129032259,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1110',
    id: 'f16e2237-65e6-437f-90c1-e44606786662',
    position: {
      x: 1220.888888888889,
      y: 882.3724340175953,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1111',
    id: '017865d4-0327-407e-9e0e-513b0d5cbd3a',
    position: {
      x: 1256.5046296296296,
      y: 892.5307917888563,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1112',
    id: 'bfc9e2ae-40f9-4ed4-a732-a46e8c6dd48c',
    position: {
      x: 1271.7685185185185,
      y: 877.2932551319648,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1113',
    id: 'b8c1341e-6ce7-41cf-b177-db7b25fdc2f9',
    position: {
      x: 1185.2731481481483,
      y: 816.3431085043989,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1114',
    id: 'dadb11da-63c7-41e0-80f2-e82ef9ffe09d',
    position: {
      x: 1185.2731481481483,
      y: 1024.5894428152492,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1115',
    id: 'efa6c272-e2ee-478b-b986-dde01fbe5a75',
    position: {
      x: 1185.2731481481483,
      y: 872.2140762463343,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1116',
    id: '1355d815-fa21-4329-91aa-5c2fba738173',
    position: {
      x: 1678.8055555555554,
      y: 618.2551319648094,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1117',
    id: '17d1c382-aa3f-4124-bfa0-6c54d2d7da95',
    position: {
      x: 1633.013888888889,
      y: 557.3049853372434,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1118',
    id: '33859c87-e69a-4630-8074-21d71660cf8d',
    position: {
      x: 1582.1342592592591,
      y: 542.0674486803518,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1119',
    id: '13ae2ebc-c001-4524-aa2c-1c05ff958784',
    position: {
      x: 762.9722222222222,
      y: 709.6803519061584,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1120',
    id: '9e5032d2-f816-4f06-9671-1a2ec9ec3dbb',
    position: {
      x: 661.2129629629629,
      y: 780.7888563049853,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1121',
    id: 'f4322438-8315-483d-8439-aba286ab5bc0',
    position: {
      x: 808.7638888888889,
      y: 775.7096774193549,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1122',
    id: 'aa93aae9-6483-4916-adb3-68a33cb7efe1',
    position: {
      x: 762.9722222222222,
      y: 831.5806451612904,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1123',
    id: '51eba80f-d61c-4160-9b86-0340acf7d15d',
    position: {
      x: 737.5324074074074,
      y: 862.0557184750733,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1124',
    id: '3560d5e9-ce24-4e20-9aed-ef876dc59613',
    position: {
      x: 803.6759259259259,
      y: 897.6099706744868,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1126',
    id: '7b296fbd-7231-491e-b166-366958313585',
    position: {
      x: 768.0601851851852,
      y: 999.1935483870967,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1127',
    id: 'c01240ff-697b-4032-b403-1bd5934c01a2',
    position: {
      x: 1663.5416666666665,
      y: 501.4340175953079,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1128',
    id: '87588c0c-1790-448a-a1ee-5ab834acc4c7',
    position: {
      x: 712.0925925925926,
      y: 963.6392961876833,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1129',
    id: '8e451d94-d461-4815-801b-42ecfe8ec19b',
    position: {
      x: 956.3148148148148,
      y: 1065.2228739002933,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1130',
    id: 'd10cc00d-7433-4363-ab69-505a38d966e2',
    position: {
      x: 808.7638888888889,
      y: 1060.1436950146626,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1131',
    id: '7bb5f8dd-0979-45d8-b131-5de7495783b3',
    position: {
      x: 885.0833333333333,
      y: 1100.7771260997067,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1132',
    id: '857672a3-7bd0-4e42-9fe0-e7117661680f',
    position: {
      x: 900.3472222222222,
      y: 1085.5395894428152,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1133',
    id: '17f51b7c-8d21-4b63-b7c6-cb560ba0fb31',
    position: {
      x: 910.5231481481482,
      y: 1029.6686217008796,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1134',
    id: 'eb15d2b6-7452-49fe-81a7-70df511df7af',
    position: {
      x: 1678.8055555555554,
      y: 516.6715542521995,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1135',
    id: '8abdbd94-b474-41ae-b944-f5901de15130',
    position: {
      x: 1694.0694444444443,
      y: 511.5923753665689,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1136',
    id: 'b6ce4947-2417-4ff9-ab69-75912037ade8',
    position: {
      x: 874.9074074074074,
      y: 806.1847507331378,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1137',
    id: '5a63bdcd-7034-4f7e-b240-92d4760539bf',
    position: {
      x: 1699.1574074074074,
      y: 531.9090909090909,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1138',
    id: 'dfab6a4c-1591-4f20-a88d-00ef2bbc7bba',
    position: {
      x: 1699.1574074074074,
      y: 557.3049853372434,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1139',
    id: '7c55c91c-91e2-4b3f-8c34-0f6aac426ea3',
    position: {
      x: 1694.0694444444443,
      y: 481.11730205278593,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1140',
    id: 'f8902bf5-2af0-40a6-a53a-99227fd0471e',
    position: {
      x: 1714.4212962962963,
      y: 486.1964809384164,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1141',
    id: 'c6c72a67-70a7-4ac4-b57e-b5dade771f9b',
    position: {
      x: 1760.2129629629628,
      y: 582.7008797653959,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1142',
    id: 'efe9e6f0-57eb-4179-ba96-6dc39c67e77d',
    position: {
      x: 1663.5416666666665,
      y: 420.1671554252199,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1143',
    id: 'b96c3b24-222d-49de-9cd8-edbf0a1d3e87',
    position: {
      x: 1658.4537037037037,
      y: 394.77126099706743,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1144',
    id: 'b6c642a9-2291-4248-8736-d756939da75c',
    position: {
      x: 1612.662037037037,
      y: 415.0879765395895,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1145',
    id: '79ac7e14-c681-4a76-ae96-bd0ce789834a',
    position: {
      x: 1129.3055555555557,
      y: 1369.9736070381232,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1146',
    id: 'c6de6020-8700-462f-a380-e718b8cdaf31',
    position: {
      x: 1164.9212962962963,
      y: 1359.815249266862,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1147',
    id: '472fa1e8-e77e-4ca7-9f17-7746d0b7e558',
    position: {
      x: 1149.6574074074074,
      y: 1415.6862170087977,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1148',
    id: 'd41f407c-6d22-46e0-8c9d-d9f242ac2888',
    position: {
      x: 1144.5694444444443,
      y: 1441.08211143695,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1149',
    id: '024517d1-37cb-4a6e-9b62-c8bd931b97d7',
    position: {
      x: 1220.888888888889,
      y: 1451.2404692082112,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1150',
    id: 'ae895d95-e154-4390-9a74-7c7c5013b459',
    position: {
      x: 1322.648148148148,
      y: 1461.3988269794722,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1151',
    id: 'dedae143-c0dd-42f5-b1ad-4e8df8c8b1a9',
    position: {
      x: 1302.2962962962963,
      y: 1491.873900293255,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1152',
    id: '0125be55-f8e4-4b2d-b851-9380fa3e7d41',
    position: {
      x: 1287.0324074074074,
      y: 1527.4281524926687,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1153',
    id: 'e738e5b5-202d-499f-a017-cf8a2870b0f8',
    position: {
      x: 1231.0648148148148,
      y: 1532.507331378299,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1154',
    id: '5dcde667-7527-4671-b35d-55561c51fa09',
    position: {
      x: 1795.8287037037037,
      y: 364.2961876832844,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1155',
    id: '96a28dea-7121-4568-aa74-f67bc1dbae68',
    position: {
      x: 1811.0925925925926,
      y: 369.37536656891496,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1156',
    id: '48eff012-4ea7-4189-a976-b5313643800a',
    position: {
      x: 1795.8287037037037,
      y: 364.2961876832844,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1157',
    id: 'd4ea96f4-5353-4945-b99f-ade9561d7ddc',
    position: {
      x: 1770.388888888889,
      y: 435.4046920821114,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1159',
    id: '84bccca8-7fa6-43c1-a4f0-02151c129e87',
    position: {
      x: 1363.3518518518517,
      y: 1385.2111436950147,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1160',
    id: 'e0016892-c9c8-4594-b677-c197a958d533',
    position: {
      x: 1424.4074074074074,
      y: 1369.9736070381232,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1161',
    id: '0a61fd07-f5d5-43c1-9b57-a93a99a10f3c',
    position: {
      x: 1414.2314814814815,
      y: 1380.131964809384,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1162',
    id: 'a9497a7a-1375-4472-9993-88ccdb4c7a59',
    position: {
      x: 1434.5833333333333,
      y: 1354.7360703812317,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1163',
    id: '868eb9b4-4584-4ed7-8b85-e77ae345c759',
    position: {
      x: 951.2268518518518,
      y: 1385.2111436950147,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1164',
    id: '19573645-8702-4a55-b8ec-e36b5d0a8b87',
    position: {
      x: 976.6666666666666,
      y: 1380.131964809384,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1166',
    id: 'eabf6630-f5d6-46ed-95f6-3fd92cad42c0',
    position: {
      x: 1037.7222222222222,
      y: 1395.3695014662756,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1167',
    id: 'c5e58266-448e-4f29-ad1e-dca0cda2b9a7',
    position: {
      x: 930.875,
      y: 1436.0029325513196,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1168',
    id: 'ba7be696-c4b5-4a10-8140-15bd5cedc2fd',
    position: {
      x: 895.2592592592592,
      y: 1390.2903225806451,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1169',
    id: '5683b420-8646-4ca2-96eb-67c721da8a13',
    position: {
      x: 869.8194444444445,
      y: 1420.7653958944281,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1170',
    id: '42b12fb4-9641-45d1-9acb-cea2ac5f751d',
    position: {
      x: 869.8194444444445,
      y: 1375.0527859237536,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1171',
    id: '9969d591-0b7d-490b-aebc-ebefe92d9ee8',
    position: {
      x: 910.5231481481482,
      y: 1319.1818181818182,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1172',
    id: 'f00d9b66-9c4c-426a-9c68-0a3c45e40777',
    position: {
      x: 951.2268518518518,
      y: 1278.5483870967741,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1174',
    id: '96ca648a-35b5-4dcc-ba9b-664d7545e116',
    position: {
      x: 1129.3055555555557,
      y: 801.1055718475073,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1175',
    id: 'e818db45-cc4a-4b65-9d6c-fc63a3673036',
    position: {
      x: 1078.4259259259259,
      y: 836.6598240469208,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1176',
    id: '078b34b4-1695-489b-830a-d73f5baa7bfa',
    position: {
      x: 579.8055555555555,
      y: 943.3225806451612,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1177',
    id: 'e7b272f1-4508-4902-a574-5fd174953960',
    position: {
      x: 528.9259259259259,
      y: 943.3225806451612,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1178',
    id: '2c8b3e27-c881-41e3-818a-a48b0672fabd',
    position: {
      x: 478.0462962962963,
      y: 928.0850439882698,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1179',
    id: '0db1df33-3202-42b3-a3bc-0ab2af463f4f',
    position: {
      x: 478.0462962962963,
      y: 912.8475073313783,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1180',
    id: '70ebb1f0-5fbf-4270-be2c-604e406391f4',
    position: {
      x: 452.60648148148147,
      y: 907.7683284457478,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1181',
    id: '6fbaf783-4fc7-4718-b38a-d008e76b8052',
    position: {
      x: 452.60648148148147,
      y: 943.3225806451612,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1182',
    id: 'c925c370-02e8-43f8-8707-92b1a792fe8e',
    position: {
      x: 452.60648148148147,
      y: 948.4017595307918,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1183',
    id: '38f5af27-bc77-45ef-8779-d9e530c59d98',
    position: {
      x: 457.69444444444446,
      y: 968.7184750733138,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1184',
    id: '726457b8-d6ee-400b-977d-c83ff0322a7a',
    position: {
      x: 386.46296296296293,
      y: 887.4516129032259,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1185',
    id: 'c0db1c16-f7e3-4a0a-ab02-8ee5d4c5001e',
    position: {
      x: 411.90277777777777,
      y: 836.6598240469208,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1186',
    id: '7ba5cbee-0a91-47b0-9fb6-1a9b85cda29b',
    position: {
      x: 544.1898148148148,
      y: 821.4222873900293,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1187',
    id: '05d4902d-1cb7-4cc0-8e0e-82d3bb8e65c4',
    position: {
      x: 422.0787037037037,
      y: 501.4340175953079,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1188',
    id: 'cdc45e94-2862-4a68-83df-80869b98aa15',
    position: {
      x: 396.6388888888889,
      y: 536.9882697947214,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1189',
    id: 'a698aa82-d61f-4fe1-bbbe-7562674aae32',
    position: {
      x: 447.5185185185185,
      y: 552.2258064516129,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1190',
    id: '086500e2-b214-4fdb-a8bf-1fbaa471cd7d',
    position: {
      x: 483.13425925925924,
      y: 592.8592375366569,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1191',
    id: 'd8d9f93e-4c7a-40d8-b572-022717ac22c0',
    position: {
      x: 544.1898148148148,
      y: 501.4340175953079,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1192',
    id: 'bc6e4feb-1554-462f-b166-38cdda2a6f88',
    position: {
      x: 569.6296296296296,
      y: 526.8299120234603,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1193',
    id: 'c8387774-7e35-446a-b5e3-93a7d55f9c20',
    position: {
      x: 559.4537037037037,
      y: 582.7008797653959,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1194',
    id: '42b70259-8837-4bf9-8222-316159d587bd',
    position: {
      x: 1851.7962962962963,
      y: 298.26686217008796,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1195',
    id: '1ac7c04a-ef0b-4460-b92a-7a05e3efc9cc',
    position: {
      x: 1912.8518518518517,
      y: 272.8709677419355,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1196',
    id: '4e1ab075-b03a-4c09-92fb-8f65d77d46de',
    position: {
      x: 1811.0925925925926,
      y: 217,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1197',
    id: '5967b098-b03a-45f8-9a47-c541aab05b06',
    position: {
      x: 997.0185185185185,
      y: 709.6803519061584,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1198',
    id: '8a171218-59b0-4917-b240-b50cc634090a',
    position: {
      x: 1129.3055555555557,
      y: 618.2551319648094,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1199',
    id: 'c57908b3-680c-4ec7-b553-93cb51060a6c',
    position: {
      x: 1149.6574074074074,
      y: 470.95894428152496,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1200',
    id: 'f73d819f-0972-4019-9b86-dc8515c00eb3',
    position: {
      x: 1052.986111111111,
      y: 359.217008797654,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1201',
    id: '3d39093e-1081-44dd-81de-6946b19e053b',
    position: {
      x: 1017.3703703703703,
      y: 354.13782991202345,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1202',
    id: 'd2bdf716-e3d0-44e5-b420-4419bf4dde18',
    position: {
      x: 1994.2592592592591,
      y: 801.1055718475073,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1203',
    id: '6d27366b-324e-481b-944d-b515d0f01924',
    position: {
      x: 1027.5462962962963,
      y: 283.02932551319645,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1204',
    id: '97db5f18-6f1e-41cc-8e33-0d0035cd7b28',
    position: {
      x: 1083.513888888889,
      y: 303.3460410557185,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1205',
    id: '786e99fc-5c6a-41ea-82e7-84cf63df76ac',
    position: {
      x: 1002.1064814814814,
      y: 232.2375366568915,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1207',
    id: '81f4a89b-fd21-4431-876d-18949a3bb5d6',
    position: {
      x: 2009.523148148148,
      y: 816.3431085043989,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1208',
    id: 'e1389386-cdae-450c-9964-6ac8fdb98c52',
    position: {
      x: 895.2592592592592,
      y: 196.683284457478,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1209',
    id: '8010fbb7-fd1c-43e9-a82a-b8a8116cbdbd',
    position: {
      x: 824.0277777777777,
      y: 191.6041055718475,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1210',
    id: '637c6e1a-0b9e-4d94-a3be-5b2369441d6e',
    position: {
      x: 874.9074074074074,
      y: 247.47507331378299,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1211',
    id: '23d3f7b1-1657-44cf-94da-a9fba6d45f29',
    position: {
      x: 732.4444444444445,
      y: 217,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1212',
    id: 'a354140f-367a-42a3-b132-df9e61137da9',
    position: {
      x: 2019.699074074074,
      y: 775.7096774193549,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1213',
    id: '963c54a3-71ba-4878-b0b7-f26965ae850e',
    position: {
      x: 879.9953703703703,
      y: 481.11730205278593,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1214',
    id: 'deaa5904-9fdd-4477-afd8-d4a6a6be670a',
    position: {
      x: 895.2592592592592,
      y: 511.5923753665689,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1215',
    id: '4c09fb62-613f-41c5-bfc5-ed3ecf7db8b3',
    position: {
      x: 946.1388888888889,
      y: 526.8299120234603,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1216',
    id: 'f956970d-2896-41eb-a2d5-5a3be26585da',
    position: {
      x: 915.6111111111111,
      y: 582.7008797653959,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1218',
    id: '5ab8c541-1f3a-45c6-8fdd-bd5c5de70ff5',
    position: {
      x: 1037.7222222222222,
      y: 465.8797653958944,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1219',
    id: 'e052e52a-42e2-47a5-800f-ee8544fb011d',
    position: {
      x: 859.6435185185185,
      y: 567.4633431085044,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1220',
    id: '43539edd-c0ca-47f3-8794-b67898cb8bbc',
    position: {
      x: 798.5879629629629,
      y: 592.8592375366569,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1221',
    id: '390a1b66-9812-4bc0-af2f-da3a9b0a579b',
    position: {
      x: 2029.875,
      y: 750.3137829912023,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1222',
    id: '87d1dd66-b6b3-437d-9625-146b6d584c9b',
    position: {
      x: 768.0601851851852,
      y: 592.8592375366569,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1223',
    id: '5739d730-7777-49c2-bc60-5d70a412a21b',
    position: {
      x: 747.7083333333333,
      y: 633.4926686217009,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1224',
    id: '24c098ef-3796-4b21-9aed-83456b88ad51',
    position: {
      x: 2055.314814814815,
      y: 750.3137829912023,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1225',
    id: '567cf762-54ec-498f-9f66-d6352559a254',
    position: {
      x: 727.3564814814815,
      y: 577.6217008797654,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1226',
    id: '98e9310f-b3e6-45fc-a2f5-3fc701b0a440',
    position: {
      x: 752.7962962962963,
      y: 547.1466275659824,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1227',
    id: '61117df6-9043-4545-bb3d-bb3e618b1728',
    position: {
      x: 2075.6666666666665,
      y: 775.7096774193549,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1228',
    id: '234b7a1e-4986-4394-ac7c-c497beea730a',
    position: {
      x: 798.5879629629629,
      y: 470.95894428152496,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1229',
    id: '74bd92df-22f9-47a5-90ae-e645df9cbc7c',
    position: {
      x: 824.0277777777777,
      y: 349.05865102639297,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1230',
    id: '02874c59-b76a-4b01-b165-3629c94f2ed4',
    position: {
      x: 2065.490740740741,
      y: 729.9970674486804,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1231',
    id: '7ceace8c-d1b6-4dd6-bb79-bb79e120d1ed',
    position: {
      x: 2045.138888888889,
      y: 663.9677419354839,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1232',
    id: '67eb9432-30d9-474b-93b5-fcb91cb08602',
    position: {
      x: 2146.898148148148,
      y: 740.1554252199413,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1233',
    id: 'aaca3d19-40d9-4acd-bb69-05a7ee4dddbc',
    position: {
      x: 2146.898148148148,
      y: 658.8885630498534,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1234',
    id: '3d022653-f35f-416e-b640-036ade8e17b5',
    position: {
      x: 2233.3935185185182,
      y: 699.5219941348973,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1235',
    id: 'cc1b1a60-42e5-45f9-b0e1-c537e2d24b9d',
    position: {
      x: 2238.4814814814818,
      y: 780.7888563049853,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1236',
    id: 'eea55206-c026-4659-814d-69008bdb3ae9',
    position: {
      x: 2253.7453703703704,
      y: 755.3929618768328,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1237',
    id: 'd4d2815f-6e12-4386-813a-e000cdd1adf4',
    position: {
      x: 2258.833333333333,
      y: 729.9970674486804,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1238',
    id: '0ccbd8ae-73cc-4770-9e9b-1f2c149441a7',
    position: {
      x: 2197.777777777778,
      y: 765.5513196480938,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1239',
    id: '20ded3e3-db30-4f61-aaea-11fba0db0205',
    position: {
      x: 2197.777777777778,
      y: 826.5014662756598,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1240',
    id: 'a7c0896d-3fc1-4748-a3fd-7bccede79e68',
    position: {
      x: 2182.5138888888887,
      y: 882.3724340175953,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1241',
    id: '00c7aa18-e3bc-47a5-9b44-3dee81f4a58f',
    position: {
      x: 2202.865740740741,
      y: 892.5307917888563,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1242',
    id: '3245daf3-e1b7-451d-8bd7-80a9daa4ccab',
    position: {
      x: 2248.6574074074074,
      y: 887.4516129032259,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1243',
    id: '32ab69a1-10f7-42ac-9755-59101714eb6a',
    position: {
      x: 2269.009259259259,
      y: 917.9266862170088,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1244',
    id: 'ed14f225-847b-4305-bec2-9b8173b490d9',
    position: {
      x: 2253.7453703703704,
      y: 968.7184750733138,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1245',
    id: '7fe893c9-2938-4229-8f87-be8bf4abbe1b',
    position: {
      x: 2253.7453703703704,
      y: 968.7184750733138,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1246',
    id: '9c850a89-5571-440e-8205-73553dcfab90',
    position: {
      x: 2258.833333333333,
      y: 983.9560117302053,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1247',
    id: '7b1fd456-edfc-4f4c-a11f-445ab3078fd0',
    position: {
      x: 2289.3611111111113,
      y: 963.6392961876833,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1248',
    id: '4488b170-9d33-486b-93cf-55b07524b339',
    position: {
      x: 2319.8888888888887,
      y: 938.2434017595308,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1249',
    id: '174c54e7-d419-41e8-b42f-808a56acbd95',
    position: {
      x: 2340.240740740741,
      y: 923.0058651026393,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1250',
    id: '2f348bbc-3a81-434e-af67-320e14e4169d',
    position: {
      x: 2375.8564814814813,
      y: 948.4017595307918,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1251',
    id: 'c938dbfa-029e-4117-8dbb-333c8239e593',
    position: {
      x: 2360.5925925925926,
      y: 862.0557184750733,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1252',
    id: 'cae61f53-1e58-410f-bc37-f8c1fbeb6d3e',
    position: {
      x: 2396.2083333333335,
      y: 745.2346041055719,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1253',
    id: '68a9382a-49e4-4ccc-9876-c36d706b9bf0',
    position: {
      x: 2360.5925925925926,
      y: 643.6510263929619,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1254',
    id: '7a874003-508a-4a89-a427-28a2cd673a2c',
    position: {
      x: 2350.4166666666665,
      y: 603.0175953079179,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1255',
    id: '1da200f7-b078-4be7-b381-bec3dba71549',
    position: {
      x: 2243.5694444444443,
      y: 506.5131964809384,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1256',
    id: 'cf04cc1a-2d0f-40dd-b40e-68ff4108f162',
    position: {
      x: 2299.537037037037,
      y: 491.2756598240469,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1257',
    id: '33809e91-0333-4d7c-8a51-912cf50d9cb8',
    position: {
      x: 2289.3611111111113,
      y: 415.0879765395895,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1258',
    id: '101be870-8d8d-4fb0-9cf7-fb1783bce930',
    position: {
      x: 2294.449074074074,
      y: 389.69208211143695,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1259',
    id: '4d7778f3-6f50-4d49-be21-c0225a8531fe',
    position: {
      x: 2319.8888888888887,
      y: 440.48387096774195,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1260',
    id: '29093d0b-780c-4e31-b85e-38d7605802e0',
    position: {
      x: 2330.064814814815,
      y: 333.82111436950146,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1261',
    id: 'a669c590-799e-4370-a7d1-f77deb0c8b7c',
    position: {
      x: 2263.9212962962965,
      y: 338.90029325513194,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1262',
    id: '1a8da81f-d603-4242-98ed-e71bb462a16c',
    position: {
      x: 2258.833333333333,
      y: 308.425219941349,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1263',
    id: '0aab48b9-af02-477a-90c3-1aaed60f6547',
    position: {
      x: 1999.3472222222222,
      y: 542.0674486803518,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1264',
    id: '6f351304-3da6-47de-a91d-56cd0682cfb3',
    position: {
      x: 2070.5787037037035,
      y: 572.5425219941349,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1266',
    id: '14c3161c-6119-4cec-8ea4-6552faf7d390',
    position: {
      x: 2177.425925925926,
      y: 293.1876832844575,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1267',
    id: 'ba96d727-ac79-490d-9bfa-2de28a5b53a8',
    position: {
      x: 2101.1064814814818,
      y: 242.3958944281525,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1268',
    id: '7ea47d3e-4886-4eca-8d36-63afb2bc94a1',
    position: {
      x: 2116.3703703703704,
      y: 374.45454545454544,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1269',
    id: '5337faa2-aa19-4f2c-8ee4-c5008c9810bb',
    position: {
      x: 676.4768518518518,
      y: 318.58357771260995,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1270',
    id: 'd0a59cf5-bc32-43f0-bcff-3c0911bb728b',
    position: {
      x: 701.9166666666666,
      y: 435.4046920821114,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1271',
    id: 'aa9d28d1-7892-4662-a14f-09e6758b965a',
    position: {
      x: 640.8611111111111,
      y: 547.1466275659824,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1273',
    id: '43cec758-301a-49f8-82d7-66fdb20e7f50',
    position: {
      x: 666.3009259259259,
      y: 597.9384164222874,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1274',
    id: 'b6547475-43c5-4ef2-8016-7056988a4614',
    position: {
      x: 630.6851851851852,
      y: 623.3343108504398,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1275',
    id: '09640076-4952-42f2-b422-f013d0d9ae33',
    position: {
      x: 691.7407407407406,
      y: 643.6510263929619,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1276',
    id: '562b638a-b736-4000-84c2-eb166e029644',
    position: {
      x: 2075.6666666666665,
      y: 359.217008797654,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1277',
    id: 'ee7b8093-b8e4-4872-938c-e88b268512f4',
    position: {
      x: 656.125,
      y: 689.3636363636364,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1278',
    id: '05e7d4f1-6fe9-4a35-98fd-25235d03310a',
    position: {
      x: 625.5972222222222,
      y: 704.6011730205279,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1279',
    id: 'c4e31ee2-9b73-45dd-9101-4a0540532f00',
    position: {
      x: 416.99074074074076,
      y: 684.2844574780058,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1280',
    id: 'f1ffb7e7-cedc-4309-aadf-7c1db8631692',
    position: {
      x: 452.60648148148147,
      y: 669.0469208211143,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1281',
    id: '584d5f21-9ca1-4897-a41f-fdd1e7914fcf',
    position: {
      x: 549.2777777777778,
      y: 714.7595307917888,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1282',
    id: 'c899bf08-bd64-40f1-b54c-8a3a517f1c8b',
    position: {
      x: 559.4537037037037,
      y: 770.6304985337243,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1283',
    id: '6edafa6c-4dab-4645-a121-0f73077362a2',
    position: {
      x: 513.662037037037,
      y: 765.5513196480938,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1284',
    id: '9c76e56b-c258-4d9d-bb84-cf523e0e5a87',
    position: {
      x: 478.0462962962963,
      y: 770.6304985337243,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1285',
    id: 'c8fda278-4c58-4d3f-853b-45c88aaeb881',
    position: {
      x: 381.375,
      y: 770.6304985337243,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1286',
    id: '1883196b-004e-4945-b7a4-3437da35e7ee',
    position: {
      x: 442.43055555555554,
      y: 719.8387096774193,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1287',
    id: '2b894ef4-47dc-45d2-bb73-2eb02d8ed90b',
    position: {
      x: 2029.875,
      y: 354.13782991202345,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1288',
    id: '3bf9d477-09dc-44ef-940c-8edfe9a224b6',
    position: {
      x: 2034.9629629629628,
      y: 272.8709677419355,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1289',
    id: 'cedff470-da03-4d85-9763-fd9edace737f',
    position: {
      x: 2055.314814814815,
      y: 283.02932551319645,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1290',
    id: 'c0f33416-33af-4375-87f5-bf3730adcbde',
    position: {
      x: 2177.425925925926,
      y: 450.6422287390029,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1291',
    id: 'b71aacc4-d177-431f-8fc0-cbdf04454936',
    position: {
      x: 2121.458333333333,
      y: 460.80058651026394,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1292',
    id: '7b5cf944-0f8f-40a8-993c-0be89c9a8ed3',
    position: {
      x: 2009.523148148148,
      y: 217,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1293',
    id: '408da4f9-1892-44e6-9c32-3e0f21c10ce7',
    position: {
      x: 1780.5648148148148,
      y: 531.9090909090909,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1294',
    id: 'cf275d23-9c8f-4364-a924-bd821669a013',
    position: {
      x: 1811.0925925925926,
      y: 526.8299120234603,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1295',
    id: '6b96b0c0-1656-4361-87f0-82df7700663f',
    position: {
      x: 1923.0277777777778,
      y: 531.9090909090909,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1296',
    id: '4105f656-c4c4-4ca7-8b9a-b69474dbb5bc',
    position: {
      x: 1943.3796296296296,
      y: 486.1964809384164,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1297',
    id: 'a0e9b313-21e8-4ca8-b4d5-5946c68a1b53',
    position: {
      x: 1612.662037037037,
      y: 191.6041055718475,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1298',
    id: '096f54ad-5524-433c-a2c0-8e3cfc38725b',
    position: {
      x: 1475.287037037037,
      y: 384.61290322580646,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1299',
    id: '73299cc8-8743-4f42-aa86-833f2a2ae9ab',
    position: {
      x: 340.6712962962963,
      y: 694.4428152492669,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1300',
    id: 'c192df0f-fa2b-4154-8437-b2abe0a127c3',
    position: {
      x: 350.84722222222223,
      y: 679.2052785923754,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1301',
    id: 'aed1f10c-71e0-41c0-b4cb-ca17ad983a15',
    position: {
      x: 376.28703703703707,
      y: 669.0469208211143,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1302',
    id: '4236756c-1d2a-4cbb-a743-9a3b78532eca',
    position: {
      x: 330.4953703703704,
      y: 658.8885630498534,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1303',
    id: 'a5a67ed3-1120-4afd-9eb7-9b263a38d718',
    position: {
      x: 1490.5509259259259,
      y: 293.1876832844575,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1304',
    id: '11f746e0-682b-40df-9045-ab6baaee5c66',
    position: {
      x: 361.02314814814815,
      y: 618.2551319648094,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1305',
    id: 'fb39374d-920c-4e54-a867-7fcc1bb156b7',
    position: {
      x: 355.93518518518516,
      y: 603.0175953079179,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1306',
    id: '7d87abc4-9347-4036-8cca-c9376a43eb3d',
    position: {
      x: 320.31944444444446,
      y: 603.0175953079179,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1307',
    id: 'cc797fae-6685-49d2-973f-44915797fff4',
    position: {
      x: 315.23148148148147,
      y: 628.4134897360705,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1308',
    id: 'f1cc569a-0b9c-45f8-8ba9-400626cb0f5f',
    position: {
      x: 294.8796296296296,
      y: 674.1260997067449,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1309',
    id: '312b3aa0-cd84-46a2-af12-310eae0b7bf4',
    position: {
      x: 305.05555555555554,
      y: 684.2844574780058,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1310',
    id: 'f4691745-e11e-454b-8bd3-29e40c9e2211',
    position: {
      x: 233.82407407407408,
      y: 623.3343108504398,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1311',
    id: '4acc4aef-a1c1-44be-8224-d754b413102c',
    position: {
      x: 284.7037037037037,
      y: 577.6217008797654,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1312',
    id: 'd788dfb6-1b08-48d1-aa83-6709b7e6abec',
    position: {
      x: 330.4953703703704,
      y: 491.2756598240469,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1313',
    id: '547d3a0a-5d54-46bb-8539-f5c91f3e7547',
    position: {
      x: 1353.1759259259259,
      y: 349.05865102639297,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1314',
    id: 'c5bc44c6-9289-479d-8e57-b0bf6d61e984',
    position: {
      x: 1327.736111111111,
      y: 369.37536656891496,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1315',
    id: 'ad1982bb-8b8e-40e1-9f4e-1829d1e0d057',
    position: {
      x: 1317.5601851851852,
      y: 338.90029325513194,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1316',
    id: 'a2af7d7a-cf89-4643-9bc4-1ea32255d485',
    position: {
      x: 1297.2083333333333,
      y: 343.9794721407625,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1317',
    id: 'c056a6f6-2bf8-4ae3-be9a-63697faea0d5',
    position: {
      x: 2121.458333333333,
      y: 856.9765395894428,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1318',
    id: 'c691a657-39e9-47b4-ac30-2686661c3903',
    position: {
      x: 1994.2592592592591,
      y: 862.0557184750733,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1319',
    id: '0382aeb5-ecbe-4bda-8037-cf0d5b2a1f90',
    position: {
      x: 2085.8425925925926,
      y: 902.6891495601172,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1320',
    id: '2823915a-5978-4949-9417-84c67a24f107',
    position: {
      x: 2009.523148148148,
      y: 973.7976539589442,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1321',
    id: 'cd0c0e87-e4da-49e6-b136-b9963aa180bd',
    position: {
      x: 2029.875,
      y: 943.3225806451612,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1322',
    id: '1a20ee19-5a29-4a6b-aa9a-877d80480bed',
    position: {
      x: 2060.402777777778,
      y: 1004.2727272727273,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1323',
    id: '862bff18-ba6a-4073-90d8-ec027163dfe9',
    position: {
      x: 2045.138888888889,
      y: 1014.4310850439882,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1324',
    id: '85e0838f-e88b-4399-a80e-82e527c3998f',
    position: {
      x: 1984.0833333333333,
      y: 1019.5102639296188,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1325',
    id: '1a8c198b-4b06-4f2b-974f-e2802372311c',
    position: {
      x: 1978.9953703703702,
      y: 1034.7478005865103,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1326',
    id: 'b6af32f5-68e5-4321-8c80-5653eb9dc63a',
    position: {
      x: 579.8055555555555,
      y: 323.66275659824043,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1327',
    id: '338c1c1d-98f2-4bbb-8d38-d197ca7596a4',
    position: {
      x: 564.5416666666666,
      y: 410.00879765395894,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1328',
    id: 'e2960414-f52e-4a60-8ef0-f466a989b076',
    position: {
      x: 498.39814814814815,
      y: 379.5337243401759,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1329',
    id: '396164f1-7923-4aa0-823a-da03eef7626e',
    position: {
      x: 447.5185185185185,
      y: 313.50439882697947,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1330',
    id: '7869d929-80da-4bcb-a3c7-88ae33063b06',
    position: {
      x: 472.9583333333333,
      y: 262.71260997067446,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1331',
    id: '8d0bc35f-bebf-47b8-b867-c16d7fa96f2f',
    position: {
      x: 483.13425925925924,
      y: 257.633431085044,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1332',
    id: '951eb3d7-6d21-46ac-8387-85fc52c5239a',
    position: {
      x: 462.7824074074074,
      y: 181.4457478005865,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1333',
    id: 'af44a2fa-7cbf-43a4-bba1-0c906dea1450',
    position: {
      x: 401.72685185185185,
      y: 181.4457478005865,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1334',
    id: '2ea81e55-5d1f-4076-bcfc-a4f356865d98',
    position: {
      x: 305.05555555555554,
      y: 186.52492668621701,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1335',
    id: 'a311996f-3186-4c0e-9262-69739b29bee1',
    position: {
      x: 244,
      y: 217,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1336',
    id: '2576f365-660b-4d92-ae41-aa547718db12',
    position: {
      x: 279.61574074074076,
      y: 257.633431085044,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1337',
    id: '269be7d5-fc2a-440c-b52b-40349d568e45',
    position: {
      x: 223.64814814814815,
      y: 267.791788856305,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1338',
    id: '79856b8e-dfcd-4f90-99b9-52b3f869b659',
    position: {
      x: 249.08796296296296,
      y: 338.90029325513194,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1339',
    id: 'e41a96f7-4c30-410c-bc78-fd4743e110a5',
    position: {
      x: 198.20833333333334,
      y: 420.1671554252199,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1340',
    id: 'e75ea009-acff-497f-9adf-d72ce0b96772',
    position: {
      x: 269.43981481481484,
      y: 470.95894428152496,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1341',
    id: '0279db34-e8b5-4f3d-ac77-ab4f22c79ed3',
    position: {
      x: 299.9675925925926,
      y: 288.108504398827,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1342',
    id: '3b30022c-7907-4016-b426-cf8af8ad23f7',
    position: {
      x: 335.5833333333333,
      y: 293.1876832844575,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1343',
    id: '8d97bca4-4873-42d2-bb21-db494f0991bd',
    position: {
      x: 361.02314814814815,
      y: 338.90029325513194,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1344',
    id: '34609413-41a7-49b7-b810-52c9b3e792b5',
    position: {
      x: 396.6388888888889,
      y: 369.37536656891496,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1345',
    id: '054232cc-59cb-472c-8849-bccf180a88d7',
    position: {
      x: 406.8148148148148,
      y: 425.24633431085044,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1346',
    id: '7bb95835-bfc8-4e1c-977a-7fd646ff9496',
    position: {
      x: 381.375,
      y: 440.48387096774195,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1348',
    id: '0d7680c3-5797-4a42-b1b6-3966c5a13ec7',
    position: {
      x: 381.375,
      y: 277.95014662756597,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1349',
    id: '8e17c41d-2060-4a52-ba45-d3deb9494872',
    position: {
      x: 345.75925925925924,
      y: 232.2375366568915,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1350',
    id: '2c453e5f-e7ad-4d68-83d3-ffdc345447b0',
    position: {
      x: 396.6388888888889,
      y: 643.6510263929619,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1351',
    id: '04cff46f-e4c0-4626-a6fd-1cbfaed67ab5',
    position: {
      x: 437.3425925925926,
      y: 638.5718475073313,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1352',
    id: '3b7e5878-3e49-4e20-a0b1-3387a4b56cf3',
    position: {
      x: 544.1898148148148,
      y: 450.6422287390029,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1353',
    id: 'af4f19ff-6f81-4a3c-8cb7-b604464c0090',
    position: {
      x: 2040.0509259259259,
      y: 1029.6686217008796,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1354',
    id: '8ac7dc0b-68bc-4c69-b301-4ae904d963e8',
    position: {
      x: 2055.314814814815,
      y: 1085.5395894428152,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1355',
    id: '911f7890-833f-4531-afea-df2dce8bdce6',
    position: {
      x: 2024.787037037037,
      y: 1187.123167155425,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1356',
    id: '96754dea-de5f-478e-bd18-ebcf9c080700',
    position: {
      x: 1958.6435185185185,
      y: 1085.5395894428152,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1357',
    id: '5b3996ca-dd4b-4338-8aa6-82df7df52b9d',
    position: {
      x: 1978.9953703703702,
      y: 1100.7771260997067,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1358',
    id: 'd4502ebb-8ac1-411d-8da9-59c5d17889a9',
    position: {
      x: 1994.2592592592591,
      y: 1171.8856304985338,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1359',
    id: 'c234d457-dd6b-4d9e-89ea-31de37501501',
    position: {
      x: 1984.0833333333333,
      y: 1095.697947214076,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1360',
    id: '4ac034cb-6594-4b02-b939-64724536b634',
    position: {
      x: 824.0277777777777,
      y: 1339.4985337243402,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1361',
    id: '29d23322-9074-4c00-b118-4db41ce27e23',
    position: {
      x: 859.6435185185185,
      y: 1309.0234604105572,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1362',
    id: '91526654-9b52-44a3-99a3-cd817b388bf2',
    position: {
      x: 920.699074074074,
      y: 1278.5483870967741,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1363',
    id: 'bf4a8f3c-ba3a-484b-ac87-b242be235807',
    position: {
      x: 900.3472222222222,
      y: 1242.9941348973607,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1364',
    id: '7b916a75-cee8-4c49-b3fb-df3c581b8af5',
    position: {
      x: 874.9074074074074,
      y: 1176.9648093841643,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1365',
    id: 'e5838520-30df-4dbc-a6cf-aac5daca54fd',
    position: {
      x: 829.1157407407408,
      y: 1176.9648093841643,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1366',
    id: 'd828702c-0b1d-4aa0-b60c-86bb8d3ea156',
    position: {
      x: 824.0277777777777,
      y: 1222.6774193548385,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1367',
    id: '98c78af8-7841-4e3b-a336-1cfb6b8b7ca5',
    position: {
      x: 752.7962962962963,
      y: 1176.9648093841643,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1368',
    id: '58df33bb-e193-442c-b9eb-775af76505cb',
    position: {
      x: 712.0925925925926,
      y: 1212.5190615835777,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1369',
    id: '04c27301-b9f4-43c3-bb6b-43110fd76604',
    position: {
      x: 696.8287037037037,
      y: 1212.5190615835777,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1370',
    id: 'a5d0e37a-7260-49af-b578-048475550776',
    position: {
      x: 676.4768518518518,
      y: 1237.9149560117303,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1371',
    id: '1d8aa5d1-275a-4737-81d7-6ed5fb51fa08',
    position: {
      x: 645.9490740740741,
      y: 1197.2815249266862,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1372',
    id: '45f3e762-90c7-4c05-ba5a-2240990c2ba3',
    position: {
      x: 600.1574074074074,
      y: 1192.2023460410558,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1373',
    id: 'e9615602-ab81-4419-9534-e93af81e3faa',
    position: {
      x: 762.9722222222222,
      y: 1242.9941348973607,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1374',
    id: '3cb7412b-943a-435d-aed8-106c9779abcd',
    position: {
      x: 732.4444444444445,
      y: 1253.1524926686218,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1375',
    id: 'a24f19fd-8c3a-45d9-89f2-ea0298ab00cb',
    position: {
      x: 722.2685185185185,
      y: 1324.2609970674487,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1376',
    id: 'fb655dce-454d-4ccb-85f4-a6076df9f073',
    position: {
      x: 737.5324074074074,
      y: 1339.4985337243402,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1377',
    id: '94e14376-2f2b-4f47-a9a1-89d4c062316c',
    position: {
      x: 2263.9212962962965,
      y: 1065.2228739002933,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1378',
    id: 'f514b2a3-9cd4-457b-86b7-f649b42602c6',
    position: {
      x: 2279.185185185185,
      y: 1044.9061583577713,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1379',
    id: 'a6cb0563-1d6c-4f18-8e72-fe40d73a070d',
    position: {
      x: 2289.3611111111113,
      y: 1049.9853372434018,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1380',
    id: '479cafb0-1bb4-4eba-bad2-74f9fa7f2540',
    position: {
      x: 1943.3796296296296,
      y: 1034.7478005865103,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1382',
    id: '6d084c3c-6d5e-439c-bb93-3bdd8c3fd2ce',
    position: {
      x: 1912.8518518518517,
      y: 1085.5395894428152,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1383',
    id: '331702f7-b055-426b-9c6f-e5c1b39c69e5',
    position: {
      x: 783.324074074074,
      y: 1430.9237536656892,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1384',
    id: '5cc96728-f717-405e-bc4a-adefed0b52c2',
    position: {
      x: 600.1574074074074,
      y: 1024.5894428152492,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1385',
    id: 'b883a70c-41fd-47a2-8200-07b9809786ca',
    position: {
      x: 554.3657407407406,
      y: 1024.5894428152492,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1386',
    id: 'ad4e6d86-3237-409d-860a-36fae101ba50',
    position: {
      x: 534.0138888888889,
      y: 999.1935483870967,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1387',
    id: 'e8c5b98c-9ce1-4c80-94fc-649b272874fc',
    position: {
      x: 1831.4444444444443,
      y: 1049.9853372434018,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1388',
    id: '19da33ea-3b82-4bb6-90db-1e6392f2b8e7',
    position: {
      x: 1867.0601851851852,
      y: 1044.9061583577713,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1389',
    id: 'c1392082-09eb-4956-b80f-b277f98e0cd0',
    position: {
      x: 437.3425925925926,
      y: 1055.0645161290322,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1390',
    id: '6754afee-23a2-44a1-aad0-f950ed6e92ec',
    position: {
      x: 467.8703703703703,
      y: 1121.0938416422287,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1391',
    id: '41900232-36a2-40f9-94c1-6978ec644d88',
    position: {
      x: 549.2777777777778,
      y: 1105.8563049853374,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1392',
    id: 'baf16f45-1748-4f39-8ba4-14597e3b2686',
    position: {
      x: 890.1712962962963,
      y: 1456.3196480938416,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1393',
    id: '98b384ca-9891-463f-967c-5d99ce3073d1',
    position: {
      x: 1867.0601851851852,
      y: 1090.6187683284456,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1394',
    id: 'ba406633-9be7-4e02-848a-25a20a4bb4c5',
    position: {
      x: 885.0833333333333,
      y: 1481.7155425219942,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1395',
    id: 'c1119a22-1017-42b8-b27d-f4bda5ac9dc7',
    position: {
      x: 1831.4444444444443,
      y: 1075.3812316715544,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1396',
    id: '7ceca599-b3a5-410f-a2ad-9671e95774da',
    position: {
      x: 885.0833333333333,
      y: 1512.1906158357772,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1397',
    id: '6a9e8d21-1c89-4f2b-bad3-dc64f0e54039',
    position: {
      x: 829.1157407407408,
      y: 1583.299120234604,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1398',
    id: 'e5e8a969-2373-46e6-aa9a-cb9a236be8f5',
    position: {
      x: 752.7962962962963,
      y: 1588.3782991202345,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1399',
    id: 'd93dfc6e-0772-40a1-bdae-d760f36dcacf',
    position: {
      x: 757.8842592592592,
      y: 1603.615835777126,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1400',
    id: '6c424376-d301-45d0-b734-1809d059fd5d',
    position: {
      x: 752.7962962962963,
      y: 1629.0117302052786,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1401',
    id: '86fe2078-3cef-4fac-bb23-9788bccc8cf6',
    position: {
      x: 813.8518518518518,
      y: 1659.4868035190616,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1402',
    id: '711ad120-c68b-4e8a-aca1-a39faed8ade5',
    position: {
      x: 829.1157407407408,
      y: 1745.83284457478,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1403',
    id: '72f1fc7c-6e05-4083-b7e0-c15a15d4bc86',
    position: {
      x: 701.9166666666666,
      y: 1562.9824046920821,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1404',
    id: '3b506c72-6669-4b6f-9c96-a78804c7e404',
    position: {
      x: 671.3888888888889,
      y: 1598.5366568914956,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1405',
    id: '84c41499-b352-409c-9153-63b5fff67c28',
    position: {
      x: 737.5324074074074,
      y: 1512.1906158357772,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1406',
    id: 'b33fd84d-1828-40ff-8441-c8c27041ac89',
    position: {
      x: 757.8842592592592,
      y: 1491.873900293255,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1407',
    id: '4a44287f-9da4-4782-b7c6-db21074a262d',
    position: {
      x: 768.0601851851852,
      y: 1476.6363636363635,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1409',
    id: 'f8b7eda3-cd66-4a03-8a72-a4774ac9e6bb',
    position: {
      x: 1892.5,
      y: 968.7184750733138,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1410',
    id: '031c3dea-2b6b-49d0-b02e-daa160f1a37c',
    position: {
      x: 1856.8842592592591,
      y: 983.9560117302053,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1411',
    id: '25e41c27-5fd5-4561-8a91-f68e635d8af9',
    position: {
      x: 1836.5324074074074,
      y: 1019.5102639296188,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1413',
    id: '2f753797-e84d-40d4-9ca7-71d1d3dc1497',
    position: {
      x: 1729.6851851851852,
      y: 1116.0146627565982,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1431',
    id: '99af2b5c-0a5a-49c9-a6aa-537980d39b6d',
    position: {
      x: 1714.4212962962963,
      y: 1187.123167155425,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1432',
    id: 'f2d4988f-dc36-4b39-b9e0-d77321e707eb',
    position: {
      x: 1627.9259259259259,
      y: 1207.4398826979473,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1433',
    id: 'b56fa0dc-97ac-4710-81fa-eba77343e740',
    position: {
      x: 651.037037037037,
      y: 1634.090909090909,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1434',
    id: '464d0044-8e40-4a48-9652-07b8b66ad8a1',
    position: {
      x: 595.0694444444445,
      y: 1603.615835777126,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1435',
    id: 'fd8df836-750e-4239-a616-45936a1dc8a7',
    position: {
      x: 1592.3101851851852,
      y: 1126.173020527859,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1436',
    id: '166f5310-915b-46d4-ba88-7443886bd486',
    position: {
      x: 508.5740740740741,
      y: 1618.8533724340175,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1437',
    id: 'df1a41ce-8d77-446a-96c7-b1361e9af571',
    position: {
      x: 508.5740740740741,
      y: 1588.3782991202345,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1438',
    id: '9e760075-59b8-447b-8b9f-3f4dfdada908',
    position: {
      x: 518.75,
      y: 1695.041055718475,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1439',
    id: 'cba87ae0-88b9-4fa3-b968-588f9cfc789a',
    position: {
      x: 1617.75,
      y: 1049.9853372434018,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1440',
    id: '6d9d328e-22fb-4540-b969-3b8b8e19dd65',
    position: {
      x: 696.8287037037037,
      y: 1456.3196480938416,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1441',
    id: '30d5211c-0407-4420-b36c-4afe4dcf8380',
    position: {
      x: 666.3009259259259,
      y: 1441.08211143695,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1442',
    id: 'e0f65f19-c9e8-4e2c-9b36-65f438ecb5d3',
    position: {
      x: 625.5972222222222,
      y: 1471.557184750733,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1443',
    id: '39c6476d-462d-4212-aa61-ed6eca00ccd6',
    position: {
      x: 376.28703703703707,
      y: 1359.815249266862,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1444',
    id: 'bbac7f63-8cb3-4e8d-ae19-78a3942e6e25',
    position: {
      x: 406.8148148148148,
      y: 1314.1026392961876,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1445',
    id: '6c70f20c-041b-4a45-b641-be7a7a462e89',
    position: {
      x: 442.43055555555554,
      y: 1298.865102639296,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1446',
    id: 'f1409217-845a-444e-9c73-b2de3fe9bccd',
    position: {
      x: 269.43981481481484,
      y: 1339.4985337243402,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1447',
    id: '2fcbb931-988f-48c3-b75b-37dc88c95be8',
    position: {
      x: 182.94444444444446,
      y: 1369.9736070381232,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1448',
    id: '0fe76fb7-b83e-450d-bd28-52bd47e91ae8',
    position: {
      x: 132.06481481481484,
      y: 1375.0527859237536,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1449',
    id: '370a37f4-cad9-45ef-8d5e-b0eacfb10436',
    position: {
      x: 182.94444444444446,
      y: 1542.6656891495602,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1450',
    id: '41d3b13d-33b6-4c28-81a7-d19de2ad5335',
    position: {
      x: 177.85648148148147,
      y: 1588.3782991202345,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1451',
    id: '5e8388bb-1033-4f5b-8b00-c2f4b94a152e',
    position: {
      x: 1709.3333333333333,
      y: 1283.6275659824046,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1452',
    id: '01056930-bb1a-42eb-8ba1-e97f0c816783',
    position: {
      x: 1058.074074074074,
      y: 1461.3988269794722,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1453',
    id: '5e3d04bb-0784-46a8-bd31-6a3aac4d48d2',
    position: {
      x: 1063.162037037037,
      y: 1486.7947214076246,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1454',
    id: 'f9de7dac-c0c6-4364-a2bc-6581059e32aa',
    position: {
      x: 1063.162037037037,
      y: 1512.1906158357772,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1455',
    id: 'a70326b5-7505-44a2-9764-019bc28f8efb',
    position: {
      x: 1017.3703703703703,
      y: 1502.032258064516,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1456',
    id: 'd048c361-f4e0-4e8b-93af-317b3209d60e',
    position: {
      x: 1002.1064814814814,
      y: 1476.6363636363635,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1457',
    id: '6b155953-128a-4956-af73-6266f680a830',
    position: {
      x: 951.2268518518518,
      y: 1542.6656891495602,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1458',
    id: '06145713-a278-48d8-b0a0-f0f62d9c01aa',
    position: {
      x: 930.875,
      y: 1532.507331378299,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1459',
    id: '8c7f59ea-15b0-4bd3-b411-21b406cc4b15',
    position: {
      x: 981.7546296296296,
      y: 1603.615835777126,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1460',
    id: '62835911-ea79-4550-b040-3bef6deb02e5',
    position: {
      x: 1032.6342592592591,
      y: 1562.9824046920821,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1461',
    id: 'c745cd79-8bcd-43f4-af9f-2170216e7d42',
    position: {
      x: 1068.25,
      y: 1598.5366568914956,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1462',
    id: '6b07cb71-f3a1-4261-b000-c4dbae11d679',
    position: {
      x: 1078.4259259259259,
      y: 1613.774193548387,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1463',
    id: '1c116bbb-e31b-4d91-bfb9-333415603867',
    position: {
      x: 1042.8101851851852,
      y: 1644.24926686217,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1464',
    id: '0c34518b-0218-4444-9882-25f2c21877b6',
    position: {
      x: 1139.4814814814813,
      y: 1639.1700879765397,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1465',
    id: 'd7b110f9-1305-429a-b550-62be53c4e390',
    position: {
      x: 1119.1296296296296,
      y: 1700.1202346041055,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1466',
    id: 'a2c1bd1d-fd47-46af-a3ae-6e618794ca0b',
    position: {
      x: 1175.0972222222222,
      y: 1761.0703812316715,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1467',
    id: 'cc649ba3-eef9-4eda-aa36-28842edede2c',
    position: {
      x: 1190.361111111111,
      y: 1679.8035190615835,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1468',
    id: '44c158b2-5b40-48a7-a079-06735e216361',
    position: {
      x: 1129.3055555555557,
      y: 1502.032258064516,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1469',
    id: '6890987f-3367-48c8-a29a-02598df4aa49',
    position: {
      x: 1093.6898148148148,
      y: 1542.6656891495602,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1470',
    id: 'b3c0ddd3-16d5-4715-93eb-2e8d7e8059a4',
    position: {
      x: 1088.6018518518517,
      y: 1542.6656891495602,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1471',
    id: '9e2f79ec-2d8d-444b-8ed9-dee9904997a5',
    position: {
      x: 1078.4259259259259,
      y: 1532.507331378299,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1472',
    id: 'bacb78b7-1a0d-4f85-b9bb-f299625e2535',
    position: {
      x: 1098.7777777777778,
      y: 1603.615835777126,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1473',
    id: 'eff57e8b-8316-492b-9260-2555248c2940',
    position: {
      x: 1175.0972222222222,
      y: 1557.9032258064517,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1474',
    id: 'e8ffa328-35b2-483a-861e-780df51f476d',
    position: {
      x: 1236.1527777777778,
      y: 1598.5366568914956,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1475',
    id: '07979989-7b56-4e44-9dbc-684e395b6587',
    position: {
      x: 1261.5925925925926,
      y: 1618.8533724340175,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1476',
    id: 'be88df4d-f08b-47d6-bedb-b3c2b1ca0325',
    position: {
      x: 1297.2083333333333,
      y: 1583.299120234604,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1477',
    id: 'd4fbfdf6-242d-4a18-be37-9f29b9108bb6',
    position: {
      x: 1322.648148148148,
      y: 1537.5865102639295,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1478',
    id: '126bb0ec-86e7-4262-bc0c-d19fc0c54d3a',
    position: {
      x: 1271.7685185185185,
      y: 1654.4076246334312,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1479',
    id: '5a04d28b-6cc8-4173-aa9d-e84807d5b453',
    position: {
      x: 467.8703703703703,
      y: 1314.1026392961876,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1480',
    id: '1c130fae-fc6a-4202-9bc0-208d07014c82',
    position: {
      x: 447.5185185185185,
      y: 1253.1524926686218,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1481',
    id: '6901ecb5-9a08-4e77-bfe8-09c660b10b12',
    position: {
      x: 391.5509259259259,
      y: 1268.390029325513,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1482',
    id: '9436289a-507e-4075-a218-1484f33fdcbc',
    position: {
      x: 254.17592592592592,
      y: 1248.0733137829911,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1483',
    id: '51c0288c-6381-4a1c-a9b7-3fe1d8f6c211',
    position: {
      x: 259.2638888888889,
      y: 1202.3607038123168,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1484',
    id: '7faec9ff-a026-48f3-b3de-c7a4547c3fc1',
    position: {
      x: 244,
      y: 1166.8064516129032,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1485',
    id: '8aa6ac91-36b0-4318-8186-23b06eba70d3',
    position: {
      x: 350.84722222222223,
      y: 1156.648093841642,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1486',
    id: '8423b766-f87a-45d9-af15-071275e11eee',
    position: {
      x: 366.1111111111111,
      y: 1456.3196480938416,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1487',
    id: 'c5312888-c593-47d2-a183-b7425e56862b',
    position: {
      x: 447.5185185185185,
      y: 1446.1612903225807,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1488',
    id: '3831e036-f117-4bc4-bb57-0e5b9e82a737',
    position: {
      x: 422.0787037037037,
      y: 1451.2404692082112,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1489',
    id: '6219a11d-3f98-4919-8593-9e8ed953a567',
    position: {
      x: 391.5509259259259,
      y: 1430.9237536656892,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1490',
    id: '9096d7de-7b42-4045-a217-552699066480',
    position: {
      x: 371.1990740740741,
      y: 1466.4780058651027,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1491',
    id: '478de7a9-439f-4b1b-83e0-6de61910f7a1',
    position: {
      x: 381.375,
      y: 1527.4281524926687,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1492',
    id: '2f0bdbcb-0133-46cf-b373-606c072cba8e',
    position: {
      x: 310.14351851851853,
      y: 1537.5865102639295,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1493',
    id: '89b0da20-2dd7-437f-9f26-ed8e37dd7685',
    position: {
      x: 259.2638888888889,
      y: 1517.2697947214076,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1494',
    id: '754cc791-9363-4165-921b-85157dde3951',
    position: {
      x: 254.17592592592592,
      y: 1552.824046920821,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1495',
    id: '456d10a3-ce2d-4ee2-9dee-e405254de638',
    position: {
      x: 244,
      y: 1578.2199413489736,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1496',
    id: '714d0c4b-7d50-4edd-bd31-d74c3fc07a2f',
    position: {
      x: 244,
      y: 1613.774193548387,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1497',
    id: 'a03be2a1-fc72-44a7-a728-7a66d0695e79',
    position: {
      x: 208.38425925925927,
      y: 1634.090909090909,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1498',
    id: '4716e488-0e1e-4f5c-97ee-4445703584e4',
    position: {
      x: 579.8055555555555,
      y: 1436.0029325513196,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1499',
    id: '831513c8-5b37-4c6d-9ac4-6ea528f613df',
    position: {
      x: 523.8379629629629,
      y: 1425.8445747800586,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1500',
    id: '70dd2f0d-7dca-4e59-84e0-7147596267e7',
    position: {
      x: 656.125,
      y: 1400.4486803519062,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1501',
    id: '020c9f37-8db1-4e18-8b42-cb944a3a0658',
    position: {
      x: 610.3333333333333,
      y: 1344.5777126099706,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1502',
    id: 'd286e5a4-f816-4d20-b196-8dfd407f1ddc',
    position: {
      x: 345.75925925925924,
      y: 1309.0234604105572,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1503',
    id: '78d0b760-d9e8-4ab2-b398-ac7ccc1befd6',
    position: {
      x: 447.5185185185185,
      y: 1517.2697947214076,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1504',
    id: 'df5cde76-19f3-4735-a919-f010c286d079',
    position: {
      x: 442.43055555555554,
      y: 1547.7448680351906,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1505',
    id: 'd9b0066a-f63b-4e89-8c30-bf2f42695f55',
    position: {
      x: 442.43055555555554,
      y: 1547.7448680351906,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1506',
    id: '293c721c-9939-4c7a-9650-8dd93e26f278',
    position: {
      x: 406.8148148148148,
      y: 1618.8533724340175,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1507',
    id: '3f4dae1c-b4ad-480d-9026-ec9a5cec5aa5',
    position: {
      x: 401.72685185185185,
      y: 1654.4076246334312,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1508',
    id: '506dee3e-f393-4fd3-96cd-7fa83c86fa0a',
    position: {
      x: 534.0138888888889,
      y: 1491.873900293255,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1509',
    id: '4656c548-f8a8-4529-9418-39f29d92e2a6',
    position: {
      x: 534.0138888888889,
      y: 1517.2697947214076,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1510',
    id: '977bc013-2ec7-4c2e-9e32-827ac839830f',
    position: {
      x: 620.5092592592592,
      y: 1745.83284457478,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1511',
    id: '288f3e9a-4820-406d-89a6-7f06fe97b233',
    position: {
      x: 712.0925925925926,
      y: 1847.41642228739,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1512',
    id: '9e6440d6-44e6-499a-a325-f0ad8ea31d13',
    position: {
      x: 829.1157407407408,
      y: 1827.099706744868,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1513',
    id: 'cd517b73-5f5a-4408-ba90-04fbde278f0e',
    position: {
      x: 864.7314814814814,
      y: 1923.6041055718474,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1514',
    id: 'b21ef478-a4c7-4d69-a616-ac1a066be09a',
    position: {
      x: 910.5231481481482,
      y: 1806.782991202346,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1515',
    id: 'c6200b2c-f5b3-4492-af44-fcc815761b09',
    position: {
      x: 951.2268518518518,
      y: 1781.3870967741934,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1516',
    id: 'cbe01acc-f659-4667-9fcc-0a4a6916a2eb',
    position: {
      x: 961.4027777777777,
      y: 1903.2873900293255,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1517',
    id: '149b4fb2-da8f-4136-8c91-efb7009a1603',
    position: {
      x: 946.1388888888889,
      y: 1938.841642228739,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1518',
    id: '06d2fd5d-9004-4d58-8d61-543d9275ae7d',
    position: {
      x: 966.4907407407408,
      y: 1959.158357771261,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1519',
    id: 'ffbfdeaf-ff9a-4362-b696-d5cd1b4ba52e',
    position: {
      x: 1017.3703703703703,
      y: 1857.574780058651,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1520',
    id: '19231d15-b459-4918-9479-0a9a49e8b0ef',
    position: {
      x: 1052.986111111111,
      y: 1847.41642228739,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1521',
    id: 'ed12911f-942f-4b3d-82f5-bb395473746f',
    position: {
      x: 1007.1944444444445,
      y: 1908.366568914956,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1522',
    id: '3af26038-f644-4655-a01b-2521c051021d',
    position: {
      x: 1098.7777777777778,
      y: 1847.41642228739,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1523',
    id: '6963b168-d23e-41df-8264-3e7f80a09c80',
    position: {
      x: 920.699074074074,
      y: 1700.1202346041055,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1524',
    id: '425ec591-aecd-42df-90a9-34dd389b62c8',
    position: {
      x: 1358.263888888889,
      y: 1430.9237536656892,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1525',
    id: '67ad3070-4da4-40ba-8c71-d67946f5f743',
    position: {
      x: 1429.4953703703704,
      y: 1425.8445747800586,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1526',
    id: '2822180e-2563-414b-a748-38da42163d05',
    position: {
      x: 1460.023148148148,
      y: 1436.0029325513196,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1527',
    id: '9d7e703f-903e-47f9-b52c-003fb8cdf71d',
    position: {
      x: 1485.4629629629628,
      y: 1385.2111436950147,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1528',
    id: '1c13c61b-ee34-4aaa-a089-e68885842e0b',
    position: {
      x: 1343,
      y: 1461.3988269794722,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1529',
    id: '8c06d34e-e11a-48c2-9807-92769d93a27d',
    position: {
      x: 1393.8796296296296,
      y: 1481.7155425219942,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1530',
    id: '65006dfa-1bdc-4a9a-ab92-f44de6e69da1',
    position: {
      x: 1439.6712962962963,
      y: 1496.9530791788857,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1531',
    id: 'dc16dabe-ac30-4f24-9a3b-778cf37f140a',
    position: {
      x: 1495.638888888889,
      y: 1603.615835777126,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1532',
    id: '74099d84-2aff-427c-a401-5e323771eaa8',
    position: {
      x: 1495.638888888889,
      y: 1644.24926686217,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1533',
    id: '8fc56218-2378-46bc-90ec-7cba2b4c719e',
    position: {
      x: 1434.5833333333333,
      y: 1623.9325513196482,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1534',
    id: '03c3fb56-c16d-484c-ba23-ea955e3fdac2',
    position: {
      x: 1419.3194444444443,
      y: 1639.1700879765397,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1535',
    id: '02cf5eaa-2466-4fdb-a21d-858a03d34771',
    position: {
      x: 1348.0879629629628,
      y: 1603.615835777126,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1536',
    id: 'c3d8cb8d-e89c-47e5-a68e-9d7782ddadd6',
    position: {
      x: 1368.4398148148148,
      y: 1552.824046920821,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1537',
    id: '8040ec33-89b1-4982-97f8-79ac80b36077',
    position: {
      x: 1358.263888888889,
      y: 1512.1906158357772,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1538',
    id: 'a7313dde-d605-42e7-a490-3b87009ba351',
    position: {
      x: 1358.263888888889,
      y: 1496.9530791788857,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1539',
    id: 'fb0eb98a-9aac-4143-8c78-9c23ac58a30b',
    position: {
      x: 1393.8796296296296,
      y: 1583.299120234604,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1540',
    id: '4244abfe-3129-4c82-86be-2a459886e3a4',
    position: {
      x: 1475.287037037037,
      y: 1730.5953079178885,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1541',
    id: '4c77b01d-13a0-4c66-b629-d1635ec5532f',
    position: {
      x: 1449.8472222222222,
      y: 1776.307917888563,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1542',
    id: 'aadd44a7-82aa-470e-984d-41ba2f880879',
    position: {
      x: 1383.7037037037037,
      y: 1761.0703812316715,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1543',
    id: '6ff8a5ff-9cc2-43ad-a2e0-8d464222021a',
    position: {
      x: 1368.4398148148148,
      y: 1745.83284457478,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1544',
    id: 'b97ba4b9-f4e1-4144-9282-edf61b1b92b1',
    position: {
      x: 1343,
      y: 1755.991202346041,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1545',
    id: 'eef5da5b-c224-48a5-8a31-4c500140c6e6',
    position: {
      x: 1292.1203703703704,
      y: 1720.4369501466276,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1546',
    id: '0f344d7f-7819-4ac7-83e9-7fb510e82911',
    position: {
      x: 1281.9444444444443,
      y: 1689.9618768328446,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1547',
    id: '920c0c1d-1086-471c-be3e-cb7c5f0883ef',
    position: {
      x: 1332.8240740740741,
      y: 1700.1202346041055,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1548',
    id: '1177856d-1e7b-4fc6-8d57-0e3a6f5aa5d1',
    position: {
      x: 1668.6296296296296,
      y: 1263.3108504398826,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1549',
    id: '61233a35-be6e-4ea3-b8de-ab4b9df40dd5',
    position: {
      x: 1699.1574074074074,
      y: 1314.1026392961876,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1550',
    id: 'f2d02e37-9ee7-4cad-ba56-6f1681f756ac',
    position: {
      x: 1683.8935185185185,
      y: 1298.865102639296,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1551',
    id: 'aa02b78d-a803-4b1d-a1cf-76548ce0ad31',
    position: {
      x: 1658.4537037037037,
      y: 1324.2609970674487,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1552',
    id: '15e3ccfd-5332-4d79-a2c6-1391edf9c0a3',
    position: {
      x: 1546.5185185185185,
      y: 1253.1524926686218,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1553',
    id: 'e04e4c16-3f57-4c4e-9d68-626aab35d758',
    position: {
      x: 1587.2222222222222,
      y: 1278.5483870967741,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1554',
    id: '85041973-f40c-4527-b72a-2e412209448f',
    position: {
      x: 1582.1342592592591,
      y: 1319.1818181818182,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1555',
    id: 'e03af851-b071-4cba-ac99-1498c7a2cbd8',
    position: {
      x: 1505.8148148148148,
      y: 1166.8064516129032,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1556',
    id: '30cce00c-7ff6-4d7b-b191-307152363b79',
    position: {
      x: 1480.375,
      y: 1156.648093841642,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1557',
    id: '8b93ebad-5742-4970-bfad-b83217b6381b',
    position: {
      x: 1755.125,
      y: 1303.9442815249267,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1558',
    id: '0d2d4f21-8cc1-45df-9e18-23d1317b096a',
    position: {
      x: 1795.8287037037037,
      y: 1324.2609970674487,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1559',
    id: '62d295ae-f56d-4e5e-8346-92dd3adc0640',
    position: {
      x: 1765.3009259259259,
      y: 1354.7360703812317,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1560',
    id: '2d040bc4-2aed-406a-b13f-ca6cfee3ef2b',
    position: {
      x: 1185.2731481481483,
      y: 1872.8123167155425,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1561',
    id: '7508e21c-6cba-4001-b260-02f2e8acb59b',
    position: {
      x: 1236.1527777777778,
      y: 1867.733137829912,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1562',
    id: 'c39ded86-597f-4c4c-83b4-32ee2ec5ec45',
    position: {
      x: 1251.4166666666665,
      y: 1801.7038123167156,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1563',
    id: '767fbd0e-b667-4456-99bf-1f22b01cf064',
    position: {
      x: 1225.9768518518517,
      y: 1999.791788856305,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1565',
    id: '7410f639-db3f-459f-bd39-28377e8cb05f',
    position: {
      x: 1307.3842592592591,
      y: 1984.5542521994134,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1566',
    id: '5807044e-89f7-4335-9d5c-9cafe092fbe9',
    position: {
      x: 1353.1759259259259,
      y: 1999.791788856305,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1567',
    id: '2f6e374d-c7d3-465e-a392-8b0c3430b68f',
    position: {
      x: 1419.3194444444443,
      y: 1989.6334310850439,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1568',
    id: '4ed296b6-78ab-4a5b-8b49-15f2bfe85ad8',
    position: {
      x: 1546.5185185185185,
      y: 1979.475073313783,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1569',
    id: 'fb8e476c-5cbe-4180-9479-3a085a4d56d2',
    position: {
      x: 1261.5925925925926,
      y: 1888.049853372434,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1570',
    id: '6ab4a286-a2af-459f-b4f6-5bbdfb5d7edf',
    position: {
      x: 1337.912037037037,
      y: 1857.574780058651,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1571',
    id: '67b4d810-6bcf-4dca-8f5a-39718adf8071',
    position: {
      x: 1475.287037037037,
      y: 1867.733137829912,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1572',
    id: 'df843b50-8d7f-4abd-855e-9eb9a8e983d7',
    position: {
      x: 1510.9027777777778,
      y: 1898.208211143695,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1573',
    id: '3dbe5012-0712-4417-b77f-5d47874c5d87',
    position: {
      x: 1526.1666666666665,
      y: 1837.258064516129,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1574',
    id: '6580502e-f4bc-4a6d-8259-f002fd59d21d',
    position: {
      x: 1378.6157407407406,
      y: 1928.683284457478,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1575',
    id: '7dc6fb1f-a2d3-4aa6-b644-bf8b89cafde1',
    position: {
      x: 1409.1435185185185,
      y: 1943.9208211143696,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1576',
    id: 'c2086342-bd6e-44e6-8166-3f07663edf8a',
    position: {
      x: 1424.4074074074074,
      y: 1959.158357771261,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1577',
    id: '35096037-fcfd-44fd-9b66-964512e03733',
    position: {
      x: 1480.375,
      y: 1949,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1578',
    id: 'df871f5e-8c48-42b9-9445-3fa486c03d1d',
    position: {
      x: 1281.9444444444443,
      y: 1781.3870967741934,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1579',
    id: '7ec27318-f8c6-4a42-86e6-d0b0f8f442ea',
    position: {
      x: 1312.4722222222222,
      y: 1776.307917888563,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1580',
    id: '1af8e2c3-e514-4817-afb8-a811082d9de2',
    position: {
      x: 1592.3101851851852,
      y: 1395.3695014662756,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1581',
    id: '2360bc84-587b-403b-8c9b-445537a2a9d5',
    position: {
      x: 1612.662037037037,
      y: 1476.6363636363635,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1582',
    id: 'a4676082-2f1d-4995-8fef-13f826e9dcc8',
    position: {
      x: 1556.6944444444443,
      y: 1481.7155425219942,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1583',
    id: 'd49123e1-de10-43fc-8dd3-479bc72fa433',
    position: {
      x: 1582.1342592592591,
      y: 1517.2697947214076,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1584',
    id: '5187f658-d437-45f8-9292-d19dd90f4b1b',
    position: {
      x: 1587.2222222222222,
      y: 1517.2697947214076,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1585',
    id: '83858024-a142-4756-903e-65329af160de',
    position: {
      x: 1643.1898148148148,
      y: 1517.2697947214076,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1586',
    id: '1870a9e3-e116-4fed-9d1d-0984c3f6b745',
    position: {
      x: 1607.5740740740741,
      y: 1730.5953079178885,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1587',
    id: 'bf3d6fea-b3c2-42b5-abb5-aaf76d09fef0',
    position: {
      x: 1607.5740740740741,
      y: 1613.774193548387,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1588',
    id: '34d19fa4-cbab-4048-ad85-e9b861b14c55',
    position: {
      x: 1571.9583333333333,
      y: 1837.258064516129,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1589',
    id: '7451f865-317f-4fac-8315-94f9b2fbf42c',
    position: {
      x: 1587.2222222222222,
      y: 1832.1788856304986,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1590',
    id: 'a143cd2a-f197-47d5-b280-de2f2625f5a3',
    position: {
      x: 1597.398148148148,
      y: 1852.4956011730205,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1591',
    id: 'ea147617-9ac9-4745-af43-adebdd1d9874',
    position: {
      x: 1627.9259259259259,
      y: 1908.366568914956,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1592',
    id: '38475e4a-766a-477c-8874-c745167e71b9',
    position: {
      x: 1607.5740740740741,
      y: 1903.2873900293255,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1593',
    id: '4d80ceab-3e47-40d5-bcae-cfba2c2fae13',
    position: {
      x: 1673.7175925925926,
      y: 1943.9208211143696,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1594',
    id: 'c8a75687-47f4-4441-b641-043ad06041cc',
    position: {
      x: 1663.5416666666665,
      y: 1974.3958944281524,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1595',
    id: '584c7392-e34a-4abc-add6-c8b06daca25a',
    position: {
      x: 1760.2129629629628,
      y: 2009.950146627566,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1596',
    id: '2f77bf70-e693-4266-b50f-0e652ce8190e',
    position: {
      x: 1694.0694444444443,
      y: 1893.1290322580644,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1597',
    id: '8f125112-f463-4ed2-9be3-a3797855398e',
    position: {
      x: 1709.3333333333333,
      y: 1923.6041055718474,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1598',
    id: 'd974f93f-87ea-4e07-8bef-3bcc78ef985d',
    position: {
      x: 1719.5092592592591,
      y: 1862.6539589442816,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1599',
    id: '763d8e0d-5e19-484d-a3e3-ef0976f490e7',
    position: {
      x: 1683.8935185185185,
      y: 1862.6539589442816,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1600',
    id: '04fff3c3-4f91-48cb-ba9b-56f2ea15f5e4',
    position: {
      x: 1627.9259259259259,
      y: 1847.41642228739,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1601',
    id: '26160db5-74a7-4d04-a8b3-26f38d59eb0a',
    position: {
      x: 1648.2777777777778,
      y: 1847.41642228739,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1602',
    id: '40e88ede-b782-4b2d-9cb9-9bb0baa709e0',
    position: {
      x: 1658.4537037037037,
      y: 1791.5454545454545,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1603',
    id: '44e14eb6-e451-422f-b1f5-d29465f75aa1',
    position: {
      x: 1653.3657407407406,
      y: 1761.0703812316715,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1604',
    id: 'a7f5eaf0-1735-447e-8f2e-ff03d86dfa09',
    position: {
      x: 1704.2453703703702,
      y: 1730.5953079178885,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1605',
    id: '0ee1e777-a2d3-48b8-a54d-2f12b891565c',
    position: {
      x: 1744.9490740740741,
      y: 1735.6744868035191,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1606',
    id: '8f32f3fc-8aa6-40cd-a7d2-1013b022fea0',
    position: {
      x: 1704.2453703703702,
      y: 1771.2287390029326,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1607',
    id: '8863cecf-2254-4d53-a2af-dc16e8e31562',
    position: {
      x: 1704.2453703703702,
      y: 1781.3870967741934,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1608',
    id: '5524e429-8973-43b1-838e-4483d573d38f',
    position: {
      x: 1750.037037037037,
      y: 1700.1202346041055,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1609',
    id: 'e3a50261-95aa-4e70-90f1-8f73852056a7',
    position: {
      x: 1765.3009259259259,
      y: 1705.1994134897361,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1610',
    id: 'ddd65521-9f82-4a66-8510-0c6a74315220',
    position: {
      x: 1785.6527777777778,
      y: 1695.041055718475,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1611',
    id: '1cb8a669-698c-4ad8-9235-1ce57e9b9d5c',
    position: {
      x: 1744.9490740740741,
      y: 1684.882697947214,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1612',
    id: '73db29df-7165-4d4d-8fce-3523e7d5eb55',
    position: {
      x: 1744.9490740740741,
      y: 1639.1700879765397,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1613',
    id: 'e31a69e9-b4c8-43f4-9ff5-1a3e475ae20b',
    position: {
      x: 1755.125,
      y: 1618.8533724340175,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1614',
    id: 'f127d668-a42f-4705-876f-97875918cbc8',
    position: {
      x: 1790.7407407407406,
      y: 1603.615835777126,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1615',
    id: 'fd48e990-487e-4a21-9369-42ce0d04bf5a',
    position: {
      x: 1836.5324074074074,
      y: 1512.1906158357772,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1616',
    id: '1f18e5a1-713f-40b5-9600-0c708aa3b7a2',
    position: {
      x: 1851.7962962962963,
      y: 1517.2697947214076,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1617',
    id: '3df0de8c-0add-4478-90d1-827b093a9a47',
    position: {
      x: 1760.2129629629628,
      y: 1527.4281524926687,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1618',
    id: 'a3e25516-2d3b-432a-b4aa-0d304dd4ac96',
    position: {
      x: 1648.2777777777778,
      y: 1385.2111436950147,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1619',
    id: '14c8eaf1-fdad-470e-ba30-da7d48d7161e',
    position: {
      x: 1663.5416666666665,
      y: 1410.607038123167,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1620',
    id: '8b92f6fa-a321-4b56-87f6-cc085b3b403b',
    position: {
      x: 1673.7175925925926,
      y: 1527.4281524926687,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1621',
    id: '08b2fd8c-3c1f-44d9-b5a9-59e8bb1da956',
    position: {
      x: 1597.398148148148,
      y: 1542.6656891495602,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1622',
    id: '859af573-e631-4dca-b358-bbdbf67f26d2',
    position: {
      x: 1704.2453703703702,
      y: 1446.1612903225807,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1623',
    id: '08eae63d-71f6-4c16-a0be-209ad96162e9',
    position: {
      x: 1826.3564814814815,
      y: 1705.1994134897361,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1624',
    id: 'c9b71454-fe3b-427c-ad5e-409da9cd322d',
    position: {
      x: 1821.2685185185185,
      y: 1634.090909090909,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1625',
    id: '18eb6b4e-3a9d-4f09-a310-ad1beb39f325',
    position: {
      x: 1856.8842592592591,
      y: 1613.774193548387,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1626',
    id: '28a07d61-d063-4a29-b618-b2406dc5dacb',
    position: {
      x: 1907.763888888889,
      y: 1750.9120234604106,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1627',
    id: '7e649d8b-0434-4b36-8ca7-5dc95b66aff2',
    position: {
      x: 1928.1157407407406,
      y: 1781.3870967741934,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1628',
    id: '4cc1203b-4a69-4d8d-a8b6-5526cece39e6',
    position: {
      x: 1826.3564814814815,
      y: 1791.5454545454545,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1629',
    id: 'b8b0932f-2daf-45f0-902e-0d575b5e600d',
    position: {
      x: 1709.3333333333333,
      y: 1827.099706744868,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1630',
    id: 'cd940d6b-1052-44d0-bf1e-a6a067fbd968',
    position: {
      x: 1887.412037037037,
      y: 1664.565982404692,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1631',
    id: '8bc319ec-5dc7-40a9-8d42-16eb2c6766a2',
    position: {
      x: 1963.7314814814815,
      y: 1710.2785923753665,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1632',
    id: '50b08d90-49d1-4357-bdc1-c8bb7003a937',
    position: {
      x: 2014.611111111111,
      y: 1776.307917888563,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1633',
    id: '0de87b35-e035-4153-8003-8493ae7cf1fb',
    position: {
      x: 2146.898148148148,
      y: 1852.4956011730205,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1634',
    id: '46c81d85-0e3c-47ea-94f3-245b267c8460',
    position: {
      x: 2111.2824074074074,
      y: 1857.574780058651,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1635',
    id: '5b93d4ee-f68e-4f14-b39d-bd2371e89941',
    position: {
      x: 2096.0185185185182,
      y: 1862.6539589442816,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1636',
    id: '18a00362-4a21-4eb1-9680-e9b7291a2357',
    position: {
      x: 2004.4351851851852,
      y: 1877.891495601173,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1637',
    id: '725ade09-2aa7-4f5f-a7aa-edc653eecf78',
    position: {
      x: 2019.699074074074,
      y: 1954.0791788856304,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1638',
    id: 'ade85294-4113-4436-af16-8288e98ecd84',
    position: {
      x: 2182.5138888888887,
      y: 1928.683284457478,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1639',
    id: '5eb5c6c6-f076-4b3a-a411-774f475071cb',
    position: {
      x: 1887.412037037037,
      y: 1857.574780058651,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1640',
    id: '020aa4df-0815-422a-9f45-582dd5fdc958',
    position: {
      x: 1856.8842592592591,
      y: 1882.9706744868035,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1641',
    id: 'e3b44c17-fd52-4a23-b635-0609229935cd',
    position: {
      x: 1887.412037037037,
      y: 1928.683284457478,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1642',
    id: '801c81ef-92cc-46fe-99b2-fb443783a476',
    position: {
      x: 1841.6203703703702,
      y: 1888.049853372434,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1643',
    id: '632e9614-12a3-49fc-bdd0-c961c4e89f45',
    position: {
      x: 1755.125,
      y: 1187.123167155425,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1644',
    id: 'd42101a7-8211-4b1e-afd6-be5196c8d026',
    position: {
      x: 1816.1805555555554,
      y: 1253.1524926686218,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1645',
    id: '81e8a1ce-95c3-4560-828e-d2bcca4cf800',
    position: {
      x: 1887.412037037037,
      y: 1217.5982404692081,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1646',
    id: 'b8a3595f-dd8a-42ba-82f7-bc25238a1ffb',
    position: {
      x: 1872.148148148148,
      y: 1293.7859237536657,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1647',
    id: '5a548d45-8ec5-4fab-ae71-1dabee14c900',
    position: {
      x: 1907.763888888889,
      y: 1166.8064516129032,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1648',
    id: '44747eb7-eb80-48ef-acb7-a3b580151a1c',
    position: {
      x: 1973.9074074074074,
      y: 1410.607038123167,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1649',
    id: 'ff1fae5e-7f73-46b4-a18c-b0c83056c17f',
    position: {
      x: 1887.412037037037,
      y: 1334.4193548387098,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1650',
    id: '1aafce3c-26ae-4f97-bb71-b574ad3bee5c',
    position: {
      x: 1953.5555555555554,
      y: 1349.6568914956013,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1651',
    id: '1ee3b47a-79ed-4add-8a48-cbde1296014d',
    position: {
      x: 1826.3564814814815,
      y: 1405.5278592375366,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1652',
    id: '380eab47-f52b-48c6-9f3b-2f060397635d',
    position: {
      x: 1806.0046296296296,
      y: 1405.5278592375366,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1653',
    id: '28da3cd9-7ac0-4866-a59b-d94f7b22bd2e',
    position: {
      x: 1836.5324074074074,
      y: 1441.08211143695,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1654',
    id: 'cb6a29a0-2237-42c3-a629-2e0d57306f99',
    position: {
      x: 1994.2592592592591,
      y: 1268.390029325513,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1655',
    id: '8faf0545-76b1-4770-84c2-5a1d91fff54c',
    position: {
      x: 2040.0509259259259,
      y: 1237.9149560117303,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1656',
    id: '6a68d382-1418-43d1-98de-5aa5e50c4e8d',
    position: {
      x: 2116.3703703703704,
      y: 1166.8064516129032,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1658',
    id: 'fc341113-13f2-4101-a5e7-f36b5b222c34',
    position: {
      x: 2162.162037037037,
      y: 1227.7565982404692,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1659',
    id: '885360ec-6f6a-49fc-9669-52b1193b9322',
    position: {
      x: 2213.0416666666665,
      y: 1176.9648093841643,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1660',
    id: '7a352fbf-2225-4b06-a520-acefb3980199',
    position: {
      x: 2263.9212962962965,
      y: 1212.5190615835777,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1661',
    id: '52a7370f-b6b6-424a-9bdf-b1a63930ac44',
    position: {
      x: 2304.625,
      y: 1116.0146627565982,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1662',
    id: '8efd7efb-6b8a-4012-97ca-5ed5fc0d8acf',
    position: {
      x: 2335.152777777778,
      y: 1166.8064516129032,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1663',
    id: 'b7826e77-0705-4f1c-ad0c-5e0b700f1648',
    position: {
      x: 2330.064814814815,
      y: 1192.2023460410558,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1664',
    id: '638bac32-4896-4a50-8aaf-0ff7e6188c38',
    position: {
      x: 2319.8888888888887,
      y: 1207.4398826979473,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1665',
    id: 'dfe13d61-421e-4673-aed8-fcdea774709f',
    position: {
      x: 2284.273148148148,
      y: 1156.648093841642,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1666',
    id: '1a1c8fc5-3dc3-448b-8610-771b87975204',
    position: {
      x: 1882.3240740740741,
      y: 1491.873900293255,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1667',
    id: '3f8a943d-6b5d-478e-954d-53d0bcd0f973',
    position: {
      x: 1877.236111111111,
      y: 1451.2404692082112,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1668',
    id: '965edc16-4222-420e-95bb-9ff42d65958b',
    position: {
      x: 1948.4675925925926,
      y: 1517.2697947214076,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1669',
    id: '640d9686-1a8c-4d24-a023-cb3023c8fcbb',
    position: {
      x: 1877.236111111111,
      y: 1522.348973607038,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1670',
    id: '8c03cddc-0404-4804-a32e-0782d8138aaf',
    position: {
      x: 1872.148148148148,
      y: 1578.2199413489736,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1671',
    id: '88c3da9e-1525-4cf8-9e23-f8f6df39dd4c',
    position: {
      x: 1928.1157407407406,
      y: 1578.2199413489736,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1672',
    id: '349936cb-8c75-41af-baf5-d264131c0cff',
    position: {
      x: 1958.6435185185185,
      y: 1593.4574780058651,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1673',
    id: 'f4f108a9-41bc-483c-b6dd-d6ef4a44f6b1',
    position: {
      x: 1948.4675925925926,
      y: 1578.2199413489736,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1674',
    id: '024e99e0-986a-4a30-a2de-723cc509fb4c',
    position: {
      x: 1989.1712962962963,
      y: 1557.9032258064517,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1675',
    id: 'f43bb164-6cb4-4c9a-b77a-07099cc691fe',
    position: {
      x: 2060.402777777778,
      y: 1593.4574780058651,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1676',
    id: '7ccd7e1f-b181-45fe-a3ab-dd35d06cf345',
    position: {
      x: 2055.314814814815,
      y: 1603.615835777126,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1677',
    id: '7df768af-ef25-473f-9dde-c092615900bc',
    position: {
      x: 1999.3472222222222,
      y: 1679.8035190615835,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1678',
    id: '6876f23d-97f9-4f07-b572-a7e7653e4e3f',
    position: {
      x: 2090.9305555555557,
      y: 1639.1700879765397,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1679',
    id: 'ae263938-64c1-48ea-8826-c6da00e37757',
    position: {
      x: 2096.0185185185182,
      y: 1674.724340175953,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1680',
    id: '3f591e80-9915-445a-b964-b98ef00687a9',
    position: {
      x: 2162.162037037037,
      y: 1537.5865102639295,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1681',
    id: '33636bb1-ff70-4b79-a92c-90dd715b81e0',
    position: {
      x: 2177.425925925926,
      y: 1578.2199413489736,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1682',
    id: '3b646f30-cc6b-4409-a19e-271545935c83',
    position: {
      x: 2151.9861111111113,
      y: 1598.5366568914956,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1683',
    id: 'f39b9ce1-b165-43cd-ae21-00afc5265cc2',
    position: {
      x: 2207.9537037037035,
      y: 1573.140762463343,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1684',
    id: '56381572-b3bd-4fa7-8fc8-e67568ae88a3',
    position: {
      x: 2238.4814814814818,
      y: 1623.9325513196482,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1685',
    id: '7139b927-dedd-41a0-9c0d-ccd7195a1ce9',
    position: {
      x: 2213.0416666666665,
      y: 1664.565982404692,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1686',
    id: '0e88f227-ab5d-4cab-ae59-2ea725447f03',
    position: {
      x: 2258.833333333333,
      y: 1684.882697947214,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1687',
    id: '350956f9-a542-487c-ad52-094889e80e4d',
    position: {
      x: 2319.8888888888887,
      y: 1649.3284457478005,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1688',
    id: '275c6026-6d42-442c-85c1-f386089d12b8',
    position: {
      x: 2350.4166666666665,
      y: 1623.9325513196482,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1689',
    id: 'ad26e09d-3da3-4660-8b44-7041aa1eac19',
    position: {
      x: 2365.6805555555557,
      y: 1588.3782991202345,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1690',
    id: '80d56846-f761-4db6-a470-9bde57704abc',
    position: {
      x: 2202.865740740741,
      y: 1476.6363636363635,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1691',
    id: 'dfc4ed2a-c11f-41ba-8aa2-ecdfd945d299',
    position: {
      x: 2167.25,
      y: 1476.6363636363635,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1692',
    id: 'd387817c-d3a6-417d-bb45-a20ed469c1f3',
    position: {
      x: 2116.3703703703704,
      y: 1476.6363636363635,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1693',
    id: '7ab044e1-e811-417f-8630-29b41d50e12c',
    position: {
      x: 2045.138888888889,
      y: 1446.1612903225807,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1694',
    id: 'b948fc41-238f-4285-a0fa-9b2051da90c8',
    position: {
      x: 2050.2268518518517,
      y: 1395.3695014662756,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1695',
    id: 'a4198224-af61-4a8f-baf4-197e9b0a23a3',
    position: {
      x: 2233.3935185185182,
      y: 1339.4985337243402,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1696',
    id: 'b31bf66d-d7ae-44e2-a7a2-bc576bc04a64',
    position: {
      x: 2274.097222222222,
      y: 1273.4692082111437,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1697',
    id: '12d26e5d-4fd5-47c4-82f8-511a05f2b121',
    position: {
      x: 2350.4166666666665,
      y: 1319.1818181818182,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1698',
    id: 'f148544e-e288-4115-a06b-c5de19ddc5d0',
    position: {
      x: 2167.25,
      y: 1349.6568914956013,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1699',
    id: '12db8c8f-6f63-4fed-bc00-1b4771a72633',
    position: {
      x: 2126.5462962962965,
      y: 1344.5777126099706,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1700',
    id: 'e16af82a-d501-4769-85cc-14eaed891b81',
    position: {
      x: 2314.8009259259256,
      y: 1425.8445747800586,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1701',
    id: '408eb946-2dea-474e-b1a6-8b54b7393755',
    position: {
      x: 2345.3287037037035,
      y: 1441.08211143695,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1702',
    id: '7d92f264-b52c-4642-b3cc-47317cda3434',
    position: {
      x: 2304.625,
      y: 1522.348973607038,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1703',
    id: '075cd106-508f-4547-867e-81b5966e43f4',
    position: {
      x: 686.6527777777778,
      y: 2009.950146627566,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1706',
    id: '520f7bdf-81cd-45ea-a757-e4ef70d30f66',
    position: {
      x: 1449.8472222222222,
      y: 933.1642228739003,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1707',
    id: '5ecb08f7-dbd7-459f-a4fe-e1cb283a11db',
    position: {
      x: 1434.5833333333333,
      y: 902.6891495601172,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1708',
    id: '9cfe6955-aa73-4a90-a135-346fcdbc7bcb',
    position: {
      x: 1485.4629629629628,
      y: 897.6099706744868,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1709',
    id: 'f4416838-75f4-4288-b477-80f2c41d2025',
    position: {
      x: 1515.9907407407406,
      y: 841.7390029325513,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1711',
    id: '4cc6c34c-aa26-42eb-9427-82d6599cf47d',
    position: {
      x: 1546.5185185185185,
      y: 882.3724340175953,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1712',
    id: '2bfc9eb5-b732-4f75-8347-273dd513ce38',
    position: {
      x: 1515.9907407407406,
      y: 933.1642228739003,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1713',
    id: '9ff05c1e-23bb-46e4-ac65-6ea74d481392',
    position: {
      x: 1505.8148148148148,
      y: 983.9560117302053,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1714',
    id: 'cee5cc45-09f1-452e-8f9c-bd3cdf76123c',
    position: {
      x: 1566.8703703703704,
      y: 989.0351906158357,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1715',
    id: '390c1a8a-8db6-4eba-b152-66d79595916f',
    position: {
      x: 1551.6064814814815,
      y: 1029.6686217008796,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1716',
    id: '6a403e26-4eec-40a0-b4b1-f2d18ac8ffaf',
    position: {
      x: 483.13425925925924,
      y: 1816.941348973607,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1717',
    id: '07201213-0eaa-4b0f-8f4b-e92094f38d4b',
    position: {
      x: 1643.1898148148148,
      y: 1009.3519061583578,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1718',
    id: '5b6b8b21-7915-455a-9a41-cc6d639294f0',
    position: {
      x: 1638.1018518518517,
      y: 978.8768328445748,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1719',
    id: 'a326f0e7-c963-43ab-b612-15b0cab36d98',
    position: {
      x: 1210.712962962963,
      y: 669.0469208211143,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1720',
    id: 'a8800d71-2bd8-414f-9bcb-317e5502e21f',
    position: {
      x: 1276.8564814814815,
      y: 684.2844574780058,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1721',
    id: 'f960c971-8692-4900-b85d-3a58f5695440',
    position: {
      x: 442.43055555555554,
      y: 1791.5454545454545,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1722',
    id: '09e44179-b050-450e-949d-01537b01ed90',
    position: {
      x: 442.43055555555554,
      y: 1745.83284457478,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1723',
    id: 'a1f8cfd6-baeb-45a3-8d37-49509a73dbdc',
    position: {
      x: 376.28703703703707,
      y: 1755.991202346041,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1724',
    id: '73e8d814-6e73-4c45-bacb-1ce510163479',
    position: {
      x: 386.46296296296293,
      y: 1750.9120234604106,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1725',
    id: 'bf4a6342-5b46-4ec0-8083-941c1f3ebc0c',
    position: {
      x: 355.93518518518516,
      y: 1771.2287390029326,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1727',
    id: '5ca6c727-5b52-4ee9-b7e9-907b507d540a',
    position: {
      x: 1175.0972222222222,
      y: 343.9794721407625,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1728',
    id: 'b0c55fe2-c702-4136-974c-8c2d63afd96b',
    position: {
      x: 1124.2175925925926,
      y: 252.5542521994135,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1729',
    id: 'ebd5dc07-f116-4df8-8f95-c877110476b7',
    position: {
      x: 350.84722222222223,
      y: 211.9208211143695,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1730',
    id: '5b8a098a-58db-4926-bba1-000e7e30675c',
    position: {
      x: 350.84722222222223,
      y: 206.841642228739,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1731',
    id: 'd3642d54-63d9-4c19-8193-253fa7821009',
    position: {
      x: 401.72685185185185,
      y: 298.26686217008796,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1732',
    id: '942df20b-b3ac-4db3-86fe-b5da57538038',
    position: {
      x: 289.7916666666667,
      y: 349.05865102639297,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1733',
    id: 'c63489b9-0dcd-4dc7-9167-f2ee26ae7b43',
    position: {
      x: 513.662037037037,
      y: 506.5131964809384,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1734',
    id: '50f4267b-a7b3-4a05-8614-64bd7049b6f8',
    position: {
      x: 1007.1944444444445,
      y: 501.4340175953079,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1735',
    id: '6293aff8-db10-44e9-83c0-810192634ce7',
    position: {
      x: 1017.3703703703703,
      y: 486.1964809384164,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1736',
    id: '4e689743-8c31-4610-afa8-b168a9bd0565',
    position: {
      x: 437.3425925925926,
      y: 999.1935483870967,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1737',
    id: 'fbafe5ac-6c1e-40ef-b718-029713209fa5',
    position: {
      x: 427.16666666666663,
      y: 1171.8856304985338,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1738',
    id: 'c58918c5-5a89-4555-98c1-b0798537dc2f',
    position: {
      x: 544.1898148148148,
      y: 1324.2609970674487,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1739',
    id: '7880240b-6a1e-4821-8853-c037e039879a',
    position: {
      x: 2065.490740740741,
      y: 958.5601173020527,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1740',
    id: '7a4935b1-3fdf-4364-9c58-a747912357b2',
    position: {
      x: 2070.5787037037035,
      y: 953.4809384164223,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1741',
    id: '7240d6ca-655d-4d13-b416-0fa51e2c0b5f',
    position: {
      x: 2131.634259259259,
      y: 983.9560117302053,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1742',
    id: 'c9c500f9-b7ee-4fc9-a9c3-9f860e1e7f35',
    position: {
      x: 2151.9861111111113,
      y: 973.7976539589442,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1743',
    id: '068dab31-82ec-4ad4-a18d-2fcf69cf1db5',
    position: {
      x: 2187.6018518518517,
      y: 1065.2228739002933,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1744',
    id: 'd7b5111d-9aeb-49a7-8312-edc68da1a08f',
    position: {
      x: 2228.3055555555557,
      y: 1781.3870967741934,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1745',
    id: '624c884b-7eca-451a-aa8c-702f567e389d',
    position: {
      x: 2243.5694444444443,
      y: 1776.307917888563,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1746',
    id: '619db004-f296-4104-af48-ec19a63948ef',
    position: {
      x: 2345.3287037037035,
      y: 1877.891495601173,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1747',
    id: 'ca9aa7b1-81da-458c-9a74-a3b7a44bc1b6',
    position: {
      x: 2365.6805555555557,
      y: 1918.524926686217,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1748',
    id: '064fac23-21d5-4667-868f-36ce10daa3d0',
    position: {
      x: 2350.4166666666665,
      y: 1943.9208211143696,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1749',
    id: 'b5be84e7-89cf-499f-9902-11a02bf5d75e',
    position: {
      x: 2436.912037037037,
      y: 1949,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1750',
    id: '23d95616-c02c-4ee3-a4f8-6352ecf21bbf',
    position: {
      x: 2167.25,
      y: 1700.1202346041055,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1751',
    id: '58c0ca21-c61f-4578-bdc5-83c76fc174e5',
    position: {
      x: 1587.2222222222222,
      y: 1542.6656891495602,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1752',
    id: '0ddfac47-346c-496d-bf4d-742c25c1beb2',
    position: {
      x: 1892.5,
      y: 1725.516129032258,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1764',
    id: '6998a103-17e8-4a58-acae-ca442df1cd8c',
    position: {
      x: 137.15277777777777,
      y: 1974.3958944281524,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1833',
    id: '42de20de-e604-4613-b275-6bbb70f573db',
    position: {
      x: 1200.537037037037,
      y: 872.2140762463343,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1834',
    id: '7262ec18-c0b4-4967-893c-783b17aa74bf',
    position: {
      x: 1195.449074074074,
      y: 846.8181818181818,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1835',
    id: '7a52f91c-d6c5-42fa-b953-bbf04f58cf54',
    position: {
      x: 1195.449074074074,
      y: 846.8181818181818,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1836',
    id: '711a2cf8-22c2-4bf9-afea-0a8c30a14e30',
    position: {
      x: 1225.9768518518517,
      y: 862.0557184750733,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1837',
    id: '3867af83-ec29-48a4-9028-384858479712',
    position: {
      x: 1225.9768518518517,
      y: 831.5806451612904,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1838',
    id: 'e7644ade-097b-4883-80c2-224a51f6a4ae',
    position: {
      x: 1276.8564814814815,
      y: 851.8973607038123,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1839',
    id: 'b8c311e1-47df-4d9f-af44-610e730d5ddc',
    position: {
      x: 1266.6805555555557,
      y: 867.1348973607038,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1840',
    id: '28913c1a-d20e-4564-96e6-5e14a643dae4',
    position: {
      x: 1215.8009259259259,
      y: 821.4222873900293,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1841',
    id: 'c5684bc3-883a-4fa8-a4e0-397617da77d6',
    position: {
      x: 2416.560185185185,
      y: 201.7624633431085,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1866',
    id: 'ff73f217-bc80-40d0-9841-fa6644a6e374',
    position: {
      x: 315.23148148148147,
      y: 1689.9618768328446,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1867',
    id: '3cf5cce9-26ee-4593-a6a6-23be4f94712f',
    position: {
      x: 859.6435185185185,
      y: 1441.08211143695,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1868',
    id: '97b65f1d-8694-4537-a0be-1313934e41ce',
    position: {
      x: 768.0601851851852,
      y: 1268.390029325513,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1869',
    id: '7e13bc29-2e27-4278-b303-15efd6f3dec9',
    position: {
      x: 981.7546296296296,
      y: 872.2140762463343,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1870',
    id: 'c39dd928-2f9d-43a9-b457-dacd4461ebcc',
    position: {
      x: 1465.111111111111,
      y: 694.4428152492669,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1871',
    id: '5bde7312-c6db-42ed-9ae5-df401a36aa6f',
    position: {
      x: 1383.7037037037037,
      y: 806.1847507331378,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1872',
    id: 'f9ff1319-d424-497e-90a6-b28ff52d1f00',
    position: {
      x: 1510.9027777777778,
      y: 1303.9442815249267,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1873',
    id: '2b9845e3-88d8-4971-a90a-657b29ae6d17',
    position: {
      x: 1531.2546296296296,
      y: 1334.4193548387098,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1874',
    id: '108bc58f-dd0c-4c33-bfcf-1af6fcf697ab',
    position: {
      x: 1531.2546296296296,
      y: 1390.2903225806451,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1876',
    id: 'd08ba072-2b7a-4d3e-92ee-40e641ebdcfa',
    position: {
      x: 1770.388888888889,
      y: 1573.140762463343,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1877',
    id: 'e13bdc80-4414-4037-b225-14fd3ce002a2',
    position: {
      x: 1704.2453703703702,
      y: 1314.1026392961876,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1880',
    id: '00fb6ef3-8091-43a7-9204-66a0c6cbfa71',
    position: {
      x: 2070.5787037037035,
      y: 1192.2023460410558,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1881',
    id: '1eea89f6-8e96-42bd-b4ae-91438381c112',
    position: {
      x: 1806.0046296296296,
      y: 1070.3020527859237,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1882',
    id: '84388f7b-03de-45f5-9164-a4cd977089eb',
    position: {
      x: 1933.2037037037037,
      y: 1034.7478005865103,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1883',
    id: '60588c6c-684f-489c-b4d7-b5683520fa16',
    position: {
      x: 1887.412037037037,
      y: 897.6099706744868,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1884',
    id: 'f63f6efe-33ce-4f10-a938-be3f22e1bea5',
    position: {
      x: 1831.4444444444443,
      y: 816.3431085043989,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1887',
    id: '039dbe13-43ab-41c2-a307-90d8852db68e',
    position: {
      x: 2345.3287037037035,
      y: 343.9794721407625,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1888',
    id: '6379f3ff-0cdd-481f-a6cb-7701309160df',
    position: {
      x: 2355.5046296296296,
      y: 338.90029325513194,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1889',
    id: '693eaa1f-106c-4c9a-b21f-c361841980a2',
    position: {
      x: 2218.1296296296296,
      y: 704.6011730205279,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1892',
    id: '15c5dc03-8642-4622-a58b-5257d00d1ab9',
    position: {
      x: 2080.7546296296296,
      y: 1547.7448680351906,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1893',
    id: '1bbace70-9088-49b3-92c3-8124f61557a4',
    position: {
      x: 1363.3518518518517,
      y: 1639.1700879765397,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1894',
    id: 'a9a9f129-66d2-4835-8f00-799d1dd16120',
    position: {
      x: 589.9814814814815,
      y: 1410.607038123167,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1895',
    id: '40a77b91-2c73-4269-8216-e0721a7f244b',
    position: {
      x: 355.93518518518516,
      y: 1222.6774193548385,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1897',
    id: '6bd5a17b-de8a-4ad8-9371-4120e13ccba6',
    position: {
      x: 1923.0277777777778,
      y: 1354.7360703812317,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1898',
    id: '834f0a81-d93a-45f2-9d61-6f14b8ef98b6',
    position: {
      x: 1948.4675925925926,
      y: 1608.6950146627566,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1901',
    id: 'a138282c-105f-499d-a9e1-8f3a93423065',
    position: {
      x: 1704.2453703703702,
      y: 719.8387096774193,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1902',
    id: 'bca871b0-2b6d-4576-adf5-83815e20f0fe',
    position: {
      x: 1225.9768518518517,
      y: 831.5806451612904,
    },
  },
  {
    location: 'surface',
    multiple: 1,
    name: '1903',
    id: '1b4374c7-4f21-44df-8d07-8f98cdffeaef',
    position: {
      x: 330.4953703703704,
      y: 897.6099706744868,
    },
  },
];

export const Koroks = [...SurfaceKorok, ...SkyKorok];
