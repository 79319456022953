import useFormContextHook from '../../../../hooks/useFormContextHook';
import LynelFilterNotice from './LynelFilterNotice';

const NoticeBannerList = () => {
  const { watch } = useFormContextHook();
  const closedNoticeBanner = watch('closedNoticeBanner') ?? [];

  return (
    <>
      {/* {closedNoticeBanner?.includes('caveFilterNotice') ? null : <CaveFilterNotice />}
      {closedNoticeBanner?.includes('korokFilterNotice') ? null : <KorokFilterNotice />} */}
      {closedNoticeBanner?.includes('lynelFilterNotice') ? null : <LynelFilterNotice />}
    </>
  );
};

export default NoticeBannerList;
