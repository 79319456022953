import { ReactElement, useEffect } from 'react';
import useGetNativeEventBridge from './hooks/useGetNativeEventBridge';
import './utils/firebase';
import './styles/bottomsheet.css';
import { FormProvider, useForm } from 'react-hook-form';
import { Stack } from './stackflow';
import { storage } from './hooks/useStorage';

function App(): ReactElement {
  useGetNativeEventBridge();

  // 앱 시작일 저장 - 앱 시작일 기준으로 몇주차인지 계산하기 위함
  const mapType = storage('mapType').get();
  const visited = storage('visited').get();
  const visitedCaves = storage('visitedCaves').get();
  const visitedLynels = storage('visitedLynels').get();
  const visitedKoroks = storage('visitedKoroks').get();
  const viewFilter = storage('viewFilter').get();
  const contentsFilter = storage('contentsFilter').get();
  const closedNoticeBanner = storage('closedNoticeBanner').get();

  useEffect(() => {
    //앱을 처음 시작하는 경우 defaultMessage를 저장
    if (!mapType) {
      storage('mapType').set('surface');
    }
    if (!visited) {
      storage('visited').set([]);
    }
    if (!viewFilter) {
      storage('viewFilter').set('all');
    }
    if (!visitedLynels) {
      storage('visitedLynels').set([]);
    }
    if (!visitedCaves) {
      storage('visitedCaves').set([]);
    }
    if (!visitedKoroks) {
      storage('visitedKoroks').set([]);
    }
    if (!contentsFilter) {
      storage('contentsFilter').set(['shrine']);
    }
    if (!closedNoticeBanner) {
      storage('closedNoticeBanner').set([]);
    }
    console.log('closedNoticeBanner', closedNoticeBanner);
  }, [
    closedNoticeBanner,
    contentsFilter,
    mapType,
    viewFilter,
    visited,
    visitedCaves,
    visitedKoroks,
    visitedLynels,
  ]);

  const formMathod = useForm({
    defaultValues: {
      mapType,
      visited,
      visitedCaves,
      visitedLynels,
      visitedKoroks,
      viewFilter,
      contentsFilter,
      closedNoticeBanner,
    },
  });

  return (
    <FormProvider {...formMathod}>
      <Stack />
    </FormProvider>
  );
}

export default App;
