import { useMemo } from 'react';

import useFormContextHook from '../../../../../hooks/useFormContextHook';

import { Lynels } from '../../../constants/Lynels';
import LynelMarkerItem from './LynelMarkerItem';

export const MARKER_SIZE = 20;

const LynelMarkersList = () => {
  const { watch } = useFormContextHook();
  const mapTypeValue = watch('mapType') ?? 'surface';
  const viewFilter = watch('viewFilter') ?? 'all';
  const visitedLynels = watch('visitedLynels') ?? [];

  const filteredCavesMarkers = useMemo(() => {
    return Lynels.filter((item) => {
      const visitedCheckName = item.name + JSON.stringify(item.position);
      if (viewFilter === 'all') return item.location === mapTypeValue;
      if (viewFilter === 'visitedOnly')
        return item.location === mapTypeValue && visitedLynels?.includes(visitedCheckName);
      if (viewFilter === 'notVisitedOnly')
        return item.location === mapTypeValue && !visitedLynels?.includes(visitedCheckName);
      return item.location === mapTypeValue;
    });
  }, [mapTypeValue, viewFilter, visitedLynels]);

  return filteredCavesMarkers ? (
    <>
      {filteredCavesMarkers.map((item, index) => {
        return <LynelMarkerItem key={index} lynel={item} />;
      })}
    </>
  ) : null;
};

export default LynelMarkersList;
