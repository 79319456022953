export type LynelType = {
  location: string;
  name: string;
  fullEnName?: string;
  koName?: string;
  position: {
    x: number;
    y: number;
  };
};

export const DepthsLynels: LynelType[] = [
  {
    location: 'depths',
    name: 'Armored White/Silver Lynel',
    koName: '하얀/실버 라이넬',
    position: { x: 968, y: 389 },
  },
  {
    location: 'depths',
    name: 'Armored White Lynel',
    koName: '하얀 갈기의 라이넬',
    position: { x: 1077, y: 871 },
  },
  {
    location: 'depths',
    name: 'Armored White/Silver Lynel',
    koName: '하얀/실버 라이넬',
    position: { x: 1322, y: 870 },
  },
  {
    location: 'depths',
    name: 'Armored Silver Lynel',
    koName: '실버 라이넬',
    position: { x: 1668, y: 777 },
  },
  {
    location: 'depths',
    name: 'Armored Blue Lynel',
    koName: '푸른 갈기의 라이넬',
    position: { x: 2086, y: 776 },
  },
  {
    location: 'depths',
    name: 'Armored Blue Lynel',
    koName: '푸른 갈기의 라이넬',
    position: { x: 2239, y: 632 },
  },
  {
    location: 'depths',
    name: 'Armored Silver Lynel',
    koName: '실버 라이넬',
    position: { x: 1663, y: 1152 },
  },
  {
    location: 'depths',
    name: 'Armore Red Lynel',
    koName: '일반 (붉은) 라이넬',
    position: { x: 1462, y: 1390 },
  },
  {
    location: 'depths',
    name: 'Armored White-Maned Lynel',
    koName: '하얀 갈기의 라이넬',
    position: { x: 1857, y: 1599 },
  },
  {
    location: 'depths',
    name: 'Armored Blue Lynel',
    koName: '푸른 갈기의 라이넬',
    position: { x: 1811, y: 2030 },
  },
  {
    location: 'depths',
    name: 'Armored White Lynel',
    koName: '하얀 라이넬',
    position: { x: 1520, y: 2011 },
  },
  {
    location: 'depths',
    name: 'Armored Silver Lynel',
    koName: '실버 라이넬',
    position: { x: 639, y: 1684 },
  },
  {
    location: 'depths',
    name: 'Armore Red Lynel',
    koName: '일반 (붉은) 라이넬',
    position: { x: 1001, y: 1424 },
  },
  {
    location: 'depths',
    name: 'Armored Silver Lynel',
    koName: '실버 라이넬',
    position: { x: 1068, y: 1417 },
  },
  {
    location: 'depths',
    name: 'Silver Lynel',
    koName: '실버 라이넬',
    position: { x: 1065, y: 1429 },
  },
  {
    location: 'depths',
    name: 'Armored White Lynel',
    koName: '하얀 라이넬',
    position: { x: 1073, y: 1438 },
  },
  {
    location: 'depths',
    name: 'Blue Lynel',
    koName: '푸른 라이넬',
    position: { x: 1088, y: 1432 },
  },
  {
    location: 'depths',
    name: 'Red Lynel',
    koName: '일반 (붉은) 라이넬',
    position: { x: 1089, y: 1419 },
  },
];

export const SurfaceLynels: LynelType[] = [
  {
    location: 'surface',
    name: 'Blue-Maned Lynel',
    koName: '푸른 갈기의 라이넬',
    position: { x: 217, y: 449 },
  },
  {
    location: 'surface',
    name: 'White-Maned Lynel',
    koName: '하얀 갈기의 라이넬',
    position: { x: 226, y: 1312 },
  },
  {
    location: 'surface',
    name: 'Red-Maned Lynel',
    koName: '일반 (붉은) 라이넬',
    position: { x: 497, y: 1080 },
  },
  {
    location: 'surface',
    name: 'Blue/White/Silver-Maned Lynel',
    koName: '푸른/하얀/실버 라이넬',
    position: { x: 637, y: 651 },
  },
  {
    location: 'surface',
    name: 'Red-Maned Lynel',
    koName: '일반 (붉은) 라이넬',
    position: { x: 778, y: 729 },
  },
  {
    location: 'surface',
    name: 'Red-Maned Lynel',
    koName: '일반 (붉은) 라이넬',
    position: { x: 915, y: 1043 },
  },
  {
    location: 'surface',
    name: 'Blue-Maned Lynel',
    koName: '푸른 갈기의 라이넬',
    position: { x: 927, y: 1718 },
  },
  {
    location: 'surface',
    name: 'Blue/White/Silver-Maned Lynel',
    koName: '푸른/하얀/실버 라이넬',
    position: { x: 1139, y: 1995 },
  },
  {
    location: 'surface',
    name: 'Blue-Maned Lynel',
    koName: '푸른 갈기의 라이넬',
    position: { x: 1308, y: 1869 },
  },
  {
    location: 'surface',
    name: 'White-Maned Lynel',
    koName: '하얀 갈기의 라이넬',
    position: { x: 1632, y: 1802 },
  },
  {
    location: 'surface',
    name: 'Blue-Maned Lynel',
    koName: '푸른 갈기의 라이넬',
    position: { x: 1768, y: 1157 },
  },
  {
    location: 'surface',
    name: 'Blue/White/Silver-Maned Lynel',
    koName: '푸른/하얀/실버 라이넬',
    position: { x: 1862, y: 1213 },
  },
  {
    location: 'surface',
    name: 'Blue-Maned Lynel',
    koName: '푸른 갈기의 라이넬',
    position: { x: 1910, y: 1304 },
  },
  {
    location: 'surface',
    name: 'White-Maned Lynel',
    koName: '하얀 갈기의 라이넬',
    position: { x: 2199, y: 861 },
  },
  {
    location: 'surface',
    name: 'Red-Maned Lynel',
    koName: '일반 (붉은) 라이넬',
    position: { x: 2146, y: 393 },
  },
];

export const Lynels = [...DepthsLynels, ...SurfaceLynels];
